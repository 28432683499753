import React, { Component } from 'react';



import menuIcon from '../../assets/imgs/profile/dinner.svg';
import deliveryIcon from '../../assets/imgs/profile/delivery.svg';
import reviewIcon from '../../assets/imgs/profile/heart.svg';

import basketIcon from '../../assets/imgs/profile/shopping.svg';
import basketIconActive from '../../assets/imgs/profile/shopping-white.svg';
import planIcon from '../../assets/imgs/profile/timetable.svg';
import planIconActive from '../../assets/imgs/profile/timetable-white.svg';
import subIcon from '../../assets/imgs/profile/history.svg';
import subIconActive from '../../assets/imgs/profile/history-white.svg';


import changeIcon from '../../assets/imgs/profile/adjust.svg';
import infoIcon from '../../assets/imgs/ui/information.svg';
import planEmptyImg from '../../assets/imgs/profile/harvest.svg';

import UiAlert from '../../components/ui/modals/UiAlert.js';
import UiBtnGreen from '../../components/ui/buttons/UiBtnGreen.js';
import UiBtnPinkOutline from '../../components/ui/buttons/UiBtnPinkOutline.js';
import UiCalendar from '../../components/ui/tabs/UiCalendar.js';
import UiDocItem from '../../components/ui/cards/UiDocItem.js';
import UiHeader from '../../components/ui/header/UiHeader.js';
import UiFooter from '../../components/ui/footer/UiFooter.js';
import UiModalPay from '../../components/ui/modals/UiModalPay.js';
import UiModalTransfer from '../../components/ui/modals/UiModalTransfer.js';
import UiSwitch from '../../components/ui/forms/UiSwitch';

import UiModalChangeAdress from '../../components/ui/modals/UiModalChangeAdress.js';
import UiModalMenu from '../../components/ui/modals/UiModalMenu.js';
import UiPlanCard from '../../components/ui/cards/UiPlanCard.js';
import UiDeliveryItem from '../../components/ui/cards/UiDeliveryItem';
import UiLoader from '../../components/ui/modals/UiLoader';
import UiMenuProfile from '../../components/ui/tabs/UiMenuProfile';

import { en } from '../../i18n/en.js';
import { ru } from '../../i18n/ru.js';
import { ch } from '../../i18n/ch.js';
import { translateObject } from '../../i18n/locale.js';

import { retrieveData, storeData } from '../../services/Storage';
import { getPriceFromArray, getPriceFromArraySales, getPriceFromArrayEs, getPriceFromArraySalesEs } from '../../components/common/Calculate.js';
import { getDateWithOffset, formatDate2, formatDateDMY, formatDate2Plus, getWeekTitleByNum, getDay, getDateWithOffsetAllowed2, convertStringToDateForSending, getFormatedCurrentDate, getLastDeliveredDay, generateDatesIntervalArray, formatDateYMD } from '../../components/common/Dates';
import { getAllPlans, getDaysByCalories, getAdditionalDinner, getEShop, changePlanDate, addProlongation, stopProlongation, getProlongateList } from '../../services/Plans.js';
import { ValidateInput } from '../../components/common/Validator';
import {
    loadUser,
    saveUser,
    getOrders,
    getOrder,
    getOrdersWS,
    updateDelivery,
    updateChnage,
    addReview,
    getReviews,
    repayOrder,
} from '../../services/Users';

const AnyReactComponent = () => (
    <div classNameName='marker'></div>
);
const google = window.google;

//даты Последняя доставка будет в четверг 21/12 (22/12 и  субботу 23/12, то есть эти даты должны быть видны в систем
//Первая доставка будет в четверг 04/01
const excludeDateP1Start = "2024-12-22";
const excludeDateStart = "2024-12-23";

//день старта 2023-01-03
const excludeDateP1End = "2025-01-05";
//день старта  2023-01-04 + 1
const excludeDateEnd = "2025-01-06";

const offsetStartDateConst = "2025-01-05";


class PlansPage extends Component {

    state = {
        lang: 'ch',

        city: "",
        street: "",
        code: "",

        loader: false,
        showPayModal: false,
        plansNum: 0,
        activePlanTab: 0,
        activeItem: 1,
        activeOrderTab: 0,
        menuHeight: 0,
        subscribeActiviti: 1,
        subscribeItem: 0,
        selectedOrderId: 0,
        selectedIndex: 0,
        programSize: 0,
        replacmentsPrice: 0,

        firstTimeArr: [
            { value: "17:00 - 22:00", text: "17:00 - 22:00" },
        ],

        reviewList: [],
        changeDeliveryList: [],
        changeUserList: [],
        changeEshopList: [],
        menuDinners: [],
        menuEShop: [],
        dayListArrDelivery: [],
        dayListArr: [],
        weeksList: [],
        ordersList: [],
        prolongationList: [],
        fullList: [],

        user: {

        },
        selectedOrder: {
            size: 0
        },
        activeDay: {
            dinner1: null,
            dinner2: null,
            dinner3: null,
            dinner4: null,
            dinner5: null,
        },
    }

    constructor(props) {
        super(props);
        let _lang = retrieveData("ikLocale");
        if (_lang == null) _lang = 'ch';
        if (_lang == 'ch') global.locale = ch;
        if (_lang == 'ru') global.locale = ru;
        if (_lang == 'en') global.locale = en;
        this.setState({ lang: _lang });
    }

    changeLang(val) {
        let _lang = 'ch';
        if (val == 0) _lang = 'ru';
        if (val == 1) _lang = 'ch';
        if (val == 2) _lang = 'en';
        if (_lang == 'ch') global.locale = ch;
        if (_lang == 'ru') global.locale = ru;
        if (_lang == 'en') global.locale = en;
        this.setState({ lang: _lang });
        storeData("ikLocale", _lang);
        //this._load();
    }



    locale(obj, val) {
        let line = "";
        if (val == "title") {
            if (this.state.lang == "ch") line = obj.title_ch;
            if (this.state.lang == "en") line = obj.title_en;
            if (this.state.lang == "ru") line = obj.title_ru;
        }
        if (val == "description") {
            if (this.state.lang == "ch") line = obj.description_ch;
            if (this.state.lang == "en") line = obj.description_en;
            if (this.state.lang == "ru") line = obj.description_ru;
        }
        if (val == "full_description") {
            if (this.state.lang == "ch") line = obj.full_description_ch;
            if (this.state.lang == "en") line = obj.full_description_en;
            if (this.state.lang == "ru") line = obj.full_description_ru;
        }
        return line;
    }

    componentDidMount() {
        this._load();
        window.addEventListener('scroll', () => {
            //if (document.documentElement.scrollTop > 160) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
        });
        window.addEventListener('resize', () => {
            this.setState({
                menuHeight: document.getElementById('root').offsetHeight,
            });
        });
    }

    initialize() {
        var input = document.getElementById('searchTextField');
        var options = {
            types: ['geocode']
        };
        var autocomplete = new google.maps.places.Autocomplete(input, options);
        autocomplete.setFields(['address_component']);
        autocomplete.addListener('place_changed', () => {
            var componentForm = {
                street_number: 'short_name',
                route: 'long_name',
                locality: 'long_name',
                administrative_area_level_1: 'short_name',
                country: 'long_name',
                postal_code: 'short_name'
            };

            var place = autocomplete.getPlace();
            if (place) {
                for (var i = 0; i < place.address_components.length; i++) {
                    var addressType = place.address_components[i].types[0];
                    if (componentForm[addressType]) {
                        var val = place.address_components[i][componentForm[addressType]];
                        componentForm[addressType] = val;
                        if (componentForm.route != 'long_name') {
                            if (componentForm.street_number == 'short_name') componentForm.street_number = "";
                            document.getElementById("searchTextField").value = componentForm.route + (componentForm.street_number != "" ? ", " : "") + componentForm.street_number;
                            this.setState({ street: componentForm.route + ", " + componentForm.street_number })
                        }
                        if (componentForm.postal_code != 'short_name') {
                            document.getElementById("codeTextField").value = componentForm.postal_code;
                            this.setState({ code: componentForm.postal_code });
                        }
                        if (componentForm.locality != 'long_name') {
                            document.getElementById("cityTextField").value = componentForm.locality;
                            this.setState({ city: componentForm.locality });
                        }
                        if (componentForm.locality == 'long_name' && componentForm.administrative_area_level_1 != 'short_name') {
                            document.getElementById("cityTextField").value = componentForm.administrative_area_level_1;
                            this.setState({ city: componentForm.administrative_area_level_1 });
                        }
                    }
                }

            }

            console.log(place, componentForm);

        });

    }

    _alert(_title = null, _text) {
        this.setState({
            showAlert: true,
            alertTitle: _title,
            alertText: _text
        })
    }


    _addRating(_did, _val) {
        addReview(this.state.user.api_token, this.state.user.id, _did, _val).then((res) => {
            console.log(res);
        })
    }

    _hasChange(_item) {
        let ff = false;
        if (_item.dinner1_may_change == 1) ff = true;
        if (_item.dinner2_may_change == 1) ff = true;
        if (_item.dinner3_may_change == 1) ff = true;
        if (_item.dinner4_may_change == 1) ff = true;
        if (_item.dinner5_may_change == 1) ff = true;
        return ff;
    }


    _getItemByDay(_id) {
        this.state.fullList.map((item) => {
            if (_id == item.id) {
                item.weekDay = getWeekTitleByNum(getDay(item.date));
                this.setState({ activeDay: item });
                console.log(item)
            }
        })
    }

    _getTotalMetrics(_item) {
        let _ck = 0, _p = 0, _f = 0, _c = 0;
        if (this._checkChangeEshop(_item.value)) {
            _ck = _ck + this._checkChangeEshop(_item.value).calories;
            _p = _p + this._checkChangeEshop(_item.value).protein;
            _f = _f + this._checkChangeEshop(_item.value).fats;
            _c = _c + this._checkChangeEshop(_item.value).carbohydrates;
        }

        if (_item.dinner1) {
            _ck = _ck + _item.dinner1[0].calories;
            _p = _p + _item.dinner1[0].protein;
            _f = _f + _item.dinner1[0].fats;
            _c = _c + _item.dinner1[0].carbohydrates;
        }
        if (_item.dinner2) {
            _ck = _ck + _item.dinner2[0].calories;
            _p = _p + _item.dinner2[0].protein;
            _f = _f + _item.dinner2[0].fats;
            _c = _c + _item.dinner2[0].carbohydrates;
        }
        if (_item.dinner3) {
            _ck = _ck + _item.dinner3[0].calories;
            _p = _p + _item.dinner3[0].protein;
            _f = _f + _item.dinner3[0].fats;
            _c = _c + _item.dinner3[0].carbohydrates;
        }
        if (_item.dinner4) {
            _ck = _ck + _item.dinner4[0].calories;
            _p = _p + _item.dinner4[0].protein;
            _f = _f + _item.dinner4[0].fats;
            _c = _c + _item.dinner4[0].carbohydrates;
        }
        if (_item.dinner5) {
            _ck = _ck + _item.dinner5[0].calories;
            _p = _p + _item.dinner5[0].protein;
            _f = _f + _item.dinner5[0].fats;
            _c = _c + _item.dinner5[0].carbohydrates;
        }

        return {
            ck: _ck,
            p: _p,
            f: _f,
            c: _c
        }
    }

    _getPrice() {
        let _price = 0;
        if (this.state.cart) {
            if (this.state.cart.plan.sales) {
                _price = getPriceFromArraySalesEs(this.state.dayListArr, this.state.changeEshopList, this.state.cart.days, this.state.cart.size, this.state.cart.count, this.state.cart.plan.sales[0].value);
            } else {
                _price = getPriceFromArrayEs(this.state.dayListArr, this.state.changeEshopList, this.state.cart.days, this.state.cart.size, this.state.cart.count);
            }
        }
        return _price;
    }

    _getAdditional(_type) {
        this.setState({ loader: true, changeItemBeforeId: _type });
        getAdditionalDinner(_type).then((res) => {
            this.setState({ loader: false, showMenu: true, menuDinners: res.response });
            console.log(res);
        })
    }

    _addChange(_day, _from, _to, _to_item) {
        console.log(_day, _from, _to, _to_item);

        let _oldPrice = 0;
        if (_from == 0) _oldPrice = this.state.activeDay.dinner1[0].price;
        if (_from == 1) _oldPrice = this.state.activeDay.dinner2[0].price;
        if (_from == 2) _oldPrice = this.state.activeDay.dinner3[0].price;
        if (_from == 3) _oldPrice = this.state.activeDay.dinner4[0].price;
        if (_from == 4) _oldPrice = this.state.activeDay.dinner5[0].price;
        console.log(this.state.activeDay, _oldPrice, _to_item.price)

        let _arr = [];

        let f = false, i = 0;
        _arr.map((item, index) => {
            if (item.day == _day && item.bid == _from) {
                i = index;
                f = true;
            }
        });
        if (f) {
            _arr.splice(i, 1);
        }
        _arr.push({
            day: _day,
            bid: _from,
            aid: _to,
            item: _to_item,
        });
        this.setState({ changeUserList: _arr }, () => {
            if (_oldPrice < _to_item.price) {

                this._alert(global.locale.plans_alert_pay_1 + " " + (_to_item.price - _oldPrice) + " " + global.locale.plans_alert_pay_2)
            }
            updateChnage(this.state.user.api_token, this.state.user.id, this.state.selectedOrderId, this.state.changeUserList).then((res) => {
                console.log(res);
                if (res.response.is_pay) {

                    /*
                    this.setState({
                        replacmentsPrice: res.response.price,
                        showPayModal: true,
                    })
                    */
                }
            })
        });
    }

    _checkChange(_day, _from) {
        let _arr = this.state.changeUserList;
        let _item = null;
        _arr.map((item, index) => {
            //console.log(item.day ,_day , item.bid , _from);
            if (item.day == _day && item.bid == _from) _item = item.item;
        });
        return _item;
    }

    _addChangeDelivery(_day, _city, _street, _code, _daypart, _time, _comment) {
        console.log(
            _day, _city, _street,
            _code, _daypart, _time,
            _comment
        );

        if (_street != "") {
            console.log(_day, _city, _street, _daypart, _time, _comment);
            let _arr = this.state.changeDeliveryList;
            let f = false, i = 0;
            _arr.map((item, index) => {
                if (item.day == _day) {
                    i = index;
                    f = true;
                }
            });
            if (f) {
                _arr.splice(i, 1);
            }
            _arr.push({
                day: _day,
                city: _city,
                street: _street,
                ftdaypart: _daypart,
                code: _code,
                fttime: _time,
                comment: _comment,
            });
            this.setState({ changeDeliveryList: _arr }, () => {
                this._updateDilivery();
            });
        }
    }

    _checkChangeDelivery(_day) {
        let _arr = this.state.changeDeliveryList;
        let _item = null;

        _arr.map((item, index) => {
            if ((item.day) == formatDateYMD(_day)) _item = item;
        });
        return _item;
    }

    _addChangeEshop(_day, _id, _item) {
        console.log(_day, _id, _item);
        let _arr = this.state.changeEshopList;
        let f = false, i = 0;
        _arr.map((item, index) => {
            if (item.day == _day) {
                i = index;
                f = true;
            }
        });
        if (f) {
            _arr.splice(i, 1);
        }
        _arr.push({
            day: _day,
            id: _id,
            item: _item
        });
        this.setState({ changeEshopList: _arr });
    }

    _checkChangeEshop(_day) {
        let _arr = this.state.changeEshopList;
        let _item = null;
        _arr.map((item, index) => {
            if (item.day == _day) _item = item.item;
        });
        return _item;
    }

    _updateDilivery() {
        updateDelivery(this.state.user.api_token, this.state.selectedOrderId, this.state.changeDeliveryList).then((res) => {
            console.log(res);
            if (res.response) {

            } else {
                alert(global.locale.plans_alert_error);
            }
        })
    }

    //exclude dates for program Pn-Pt or Pn-Sb
    _excludeDayOff(_arr, _type) {
        let arr = [];
        let replaceArr = [];
        let offsetStartDate = null;
        let index = 0;
        let arrMap = JSON.parse(JSON.stringify(_arr));
        if (_type == 1) {
            //find date
            arrMap.map((item, index) => {
                if (new Date(item.date).getDay() != 0 && new Date(item.date).getDay() != 6) {
                    if (arrMap[index - 1]) offsetStartDate = item.date;
                    arr.push(item)
                }
            })
            var dd = new Date(offsetStartDate);
            dd.setDate(dd.getDate() + 1);
            offsetStartDate = dd;
            arrMap.map((item, itemIndex) => {
                if (new Date(item.date).getDay() != 0 && new Date(item.date).getDay() != 6) {

                } else {
                    var d = new Date(offsetStartDate);
                    d.setDate(d.getDate() + (index));
                    index++;
                    if (d.getDay() == 0 || d.getDay() == 6) {
                        d.setDate(d.getDate() + 1);
                        index++;
                    }
                    if (d.getDay() == 0 || d.getDay() == 6) {
                        d.setDate(d.getDate() + 1);
                        index++;
                    }
                    item.day = formatDate2(d);
                    item.dayName = getWeekTitleByNum(getDay(d));
                    item.date = d;

                    this.state.fullList.map((fitem) => {
                        if (fitem.date === formatDateYMD(d)) {
                            item.id = fitem.id;
                        }
                    })

                    replaceArr.push(item);
                }
            })
            arr = arr.concat(replaceArr);
            let unique = [];
            arr.map(x => unique.filter(a => a.day == x.day).length > 0 ? null : unique.push(x));
            unique.sort(function (a, b) {
                return new Date(a.date) - new Date(b.date);
            });
            return unique;
        } else {
            return _arr;
        }
    }

    _excludeDates(_arr, _start, _end) {
        let arr = [];
        let replaceArr = [];
        let offsetStartDate = null;
        let index = 0;
        let arrMap = JSON.parse(JSON.stringify(_arr));


        //find date
        arrMap.map((item, index) => {
            if (!(
                new Date(item.date) >= new Date(_start) &&
                new Date(item.date) <= new Date(_end))) {
                if (arrMap[index - 1]) offsetStartDate = item.date;
                arr.push(item)
            } else {

            }
        })
        //console.log("arrMap",offsetStartDate, arr,   arrMap);

        if (new Date(offsetStartDate) <= new Date(excludeDateP1Start)) {
            offsetStartDate = excludeDateEnd;
        } else {
            var dd = new Date(offsetStartDate);
            dd.setDate(dd.getDate() + 1);
            offsetStartDate = dd;
        }

        arrMap.map((item, itemIndex) => {
            if (!(
                new Date(item.date) >= new Date(_start) &&
                new Date(item.date) <= new Date(_end))) {
                //arr.push(item);
                //console.log(item.date);
            } else {
                var d = new Date(offsetStartDate);
                d.setDate(d.getDate() + (index));
                index++;
                if (d.getDay() == 0) {
                    d.setDate(d.getDate() + 1);
                    index++;
                }
                item.day = formatDate2(d);
                item.dayName = getWeekTitleByNum(getDay(d));
                item.date = d;
                replaceArr.push(item);
            }
        })
        arr = arr.concat(replaceArr);

        let unique = [];
        arr.map(x => unique.filter(a => a.day == x.day).length > 0 ? null : unique.push(x));
        unique.sort(function (a, b) {
            return new Date(a.date) - new Date(b.date);
        });

        return unique;
    }



    _excludeDeliveryDates(_arr, _wArr, _start, _end, _type = 0) {
        let arr = [];
        let replaceArr = [];
        let index = 1;
        let offsetStartDate = null;
        let arrMap = JSON.parse(JSON.stringify(_arr));
        arrMap.map((item, index) => {
          if (
            !(
              new Date(item.date) >= new Date(_start) &&
              new Date(item.date) <= new Date(_end)
            )
          ) {
            if (arrMap[index - 1]) offsetStartDate = item.date;
            arr.push(item);
          } else {
          }
        });
    
        if (new Date(offsetStartDate) <= new Date(excludeDateStart)) {
          offsetStartDate = offsetStartDateConst;
        } else {
          var dd = new Date(offsetStartDate);
          dd.setDate(dd.getDate() + 1);
          offsetStartDate = dd;
        }
        // console.log("arrMap",offsetStartDate, arr,   arrMap);
        arrMap.map((item, itemIndex) => {
          if (
            !(
              new Date(item.date) >= new Date(_start) &&
              new Date(item.date) <= new Date(_end)
            )
          ) {
          } else {
            var d = new Date(offsetStartDate);
            d.setDate(d.getDate() + index);
    
            index++;
            if(_type == 0){
              if (d.getDay() != 0 && d.getDay() != 2 && d.getDay() != 4) {
                d.setDate(d.getDate() + 1);
                index++;
              }
              if (d.getDay() != 0 && d.getDay() != 2 && d.getDay() != 4) {
                d.setDate(d.getDate() + 1);
                index++;
              }
              if (d.getDay() != 0 && d.getDay() != 2 && d.getDay() != 4) {
                d.setDate(d.getDate() + 1);
                index++;
              }
            } else {
              if (d.getDay() != 0 && d.getDay() != 6) {
                d.setDate(d.getDate() + 1);
                index++;
              }
              if (d.getDay() != 0 && d.getDay() != 6) {
                d.setDate(d.getDate() + 1);
                index++;
              }
              if (d.getDay() != 0 && d.getDay() != 6) {
                d.setDate(d.getDate() + 1);
                index++;
              }
            }
    
            item.calDay = formatDate2(d);
            item.weekDay = getWeekTitleByNum(getDay(d));
            item.date = d;
            replaceArr.push(item);
          }
        });
    
        let unique = [];
        arr = arr.concat(replaceArr);
        arr.map((x) =>
          unique.filter((a) => a.calDay == x.calDay).length > 0
            ? null
            : unique.push(x)
        );
    
        //fix if too much days
        if (unique[unique.length - 1].calDay == _wArr[_wArr.length - 1].day) {
          unique.splice(unique.length - 1, 1);
        }

        //fix if days more then prog
        let remBigIndex = null;
        for (var i = 0; i < unique.length; i++)   {       
            if(remBigIndex == null && new Date(unique[i].date) > new Date(_wArr[_wArr.length - 2].date) ) {
                remBigIndex = i  ; 
            } 
        } 

        if(remBigIndex) unique.splice(remBigIndex  ,  unique.length - remBigIndex   );
        return unique;
      }

    _stopProlongatePlan(id) {
        stopProlongation(this.state.user.api_token, id).then((res) => {
            console.log(res);
            if (res.response) {
                getProlongateList(this.state.user.id).then((res) => {
                    console.log(res);
                    if (res.success) {
                        this.setState({ prolongationList: res.response })
                    }
                })
            }
        })
    }

    _showProlongationModal(_index) {
        let _plan = this.state.ordersList[_index];
        let currentDate = new Date();
        let startDate = new Date();
        let newArr = [];
        this.state.weeksList.map((item, index) => {
            if (new Date(item.date) > currentDate) newArr.push(item);
        })
        if (newArr.length > 0) {
            startDate = new Date(newArr[newArr.length - 1].date);
            this.setState({
                showTransferModal: true,
                selectedMinPlanDate: newArr[newArr.length - 1].date,
                newPlanDaysCount: _plan.days,
                newPlanProgramSize: _plan.programSize,
                selectedPlanId: _plan.id
            })
        }
    }

    _prolongatePlan(_date, _days) {

        let _plan = this.state.ordersList[this.state.selectedIndex];
        console.log("_prolongatePlan")
        addProlongation(this.state.user.api_token, _plan.id, this.state.user.id, _date, _days).then((res) => {
            console.log(res);
            if (res.response) {
                getProlongateList(this.state.user.id).then((res) => {
                    console.log(res);
                    if (res.success) {
                        this.setState({ prolongationList: res.response })
                    }
                })
            }
        })
    }

    _checkProlongate(id) {
        var f = false;
        this.state.prolongationList.map((item) => {
            if (item.plan_id == id && item.is_del == 0) f = true;
        })
        return f;
    }

    _pausePlan(_id) {
        let f = 0;
        this.state.prolongationList.map((item) => {
            if (item.plan_id == _id) f = item.id;
        })

        stopProlongation(this.state.user.api_token, f).then((res) => {
            console.log(res)
            this._load();
        })
        /*
        let _plan = this.state.ordersList[this.state.selectedIndex];
        let currentDate = new Date();
        let startDate = new Date();
        let newArr = [];
        this.state.weeksList.map((item, index) => {
            if (new Date(item.date) > currentDate) newArr.push(item);
        })
        if (newArr.length > 0) {
            startDate = new Date(newArr[0].date);
            this.setState({
                showTransferModal: true,
                selectedMinPlanDate: newArr[0].date,
                newPlanDaysCount: newArr.length,
                selectedPlanId: _plan.id
            })
        }
        console.log(newArr, _plan, this.state.weeksList)
        */
    }

    _changePlanDate(date) {
        changePlanDate(this.state.user.api_token, this.state.selectedPlanId, date, this.state.newPlanDaysCount).then((res) => {
            console.log(res);
            this._load();
        })
    }

    _replay(_index) {
        let _cart = this.state.ordersList[_index];
        let _plan = null;

        this.state.plansList.map((item) => {
            if (item.value == _cart.program.id) _plan = item;
        })
        console.log(_cart, _plan)
        let data = {
            plan: _plan,
            itemId: _cart.calories_id,
            days: _cart.days - _cart.days_offset,
            size: _cart.size,
            count: _cart.count,
            programSize: _cart.program_size
        }
        console.log(_cart, data)
        storeData("ikCart", data);
        this.props.history.push("/cart");

    }

    _pay(_index) {
        let _cart = this.state.ordersList[_index];
        this.setState({ loader: true });
        repayOrder(_cart.token).then((res) => {
            console.log(res);
            this.setState({ loader: false });

            if (res.success) {
                if (res.response) {
                    if (res.response.return_url != "") {
                        window.location.href = res.response.url;
                        //console.log( res.response.url,  res.response.get );
                    } else {
                        this._alert(res.response.message);
                    }

                } else {
                    this._alert(global.locale.cart_alert_error);
                }
            } else {
                this._alert(global.locale.cart_alert_error);
            }

        });
    }

    _load(_showModalProlongate = false) {
        this.setState({ menuHeight: document.getElementById('root').offsetHeight });
        this.setState({ loader: true });

        let _lang = retrieveData("ikLocale");
        if (_lang == null) _lang = 'ch';
        if (_lang == 'ch') global.locale = ch;
        if (_lang == 'ru') global.locale = ru;
        if (_lang == 'en') global.locale = en;
        this.setState({ lang: _lang });



        let _user = loadUser();
        this.setState({ user: _user });
        if (_user == null) {
            this.props.history.push("/login");
        } else {

            getAllPlans().then((res) => {
                let _arr = [];
                let _lang = retrieveData("ikLocale");
                res.response.map((item) => {
                    var _call = [];
                    item.calories.map((item2) => {
                        _call.push({
                            value: item2.id,
                            title: _lang == "ru" ? item2.title_ru : _lang == "ch" ? item2.title_ch : _lang == "en" ? item2.title_en : "",
                            about: _lang == "ru" ? item2.description_ru : _lang == "ch" ? item2.description_ch : _lang == "en" ? item2.description_en : "",
                            ccalFrom: item2.from_cal,
                            ccalTo: item2.to_cal,
                        })
                    })
                    _arr.push({
                        value: item.id,
                        sales: item.sales,
                        programName: _lang == "ru" ? item.title_ru : _lang == "ch" ? item.title_ch : _lang == "en" ? item.title_en : "",
                        programAbout: _lang == "ru" ? item.description_ru : _lang == "ch" ? item.description_ch : _lang == "en" ? item.description_en : "",
                        programDescription: _lang == "ru" ? item.full_description_ru : _lang == "ch" ? item.full_description_ch : _lang == "en" ? item.full_description_en : "",

                        programName_ru: item.title_ru,
                        programAbout_ru: item.description_ru,
                        programDescription_ru: item.full_description_ru,

                        programName_ch: item.title_ch,
                        programAbout_ch: item.description_ch,
                        programDescription_ch: item.full_description_ch,

                        programName_en: item.title_en,
                        programAbout_en: item.description_en,
                        programDescription_en: item.full_description_en,

                        caloriesList: _call,
                    });
                })

                this.setState({ plansList: _arr })
            })

            getProlongateList(_user.id).then((res) => {
                console.log(res);
                if (res.success) {
                    this.setState({ prolongationList: res.response })
                }
            })

            getReviews(_user.api_token, _user.id).then((res) => {
                //console.log(res);
                if (res.success) {
                    this.setState({ reviewList: res.response })
                } else {
                    this.props.history.push("/login");
                    saveUser(null);
                }
            })

            getEShop().then((res) => {
                this.setState({ menuEShop: res.response });
            })

            getOrders(_user.api_token, _user.id).then((res) => {
                console.log("_user", res);
                if (res.success) {
                    if (res.response.length > 0) {
                        let _daysArr = [];
                        let _dates = [];
                        let _dates2 = [];



                        this.setState({
                            loader: false,
                            selectedOrderId: res.response[this.state.selectedIndex].id,
                            programSize: res.response[this.state.selectedIndex].program_size,
                            fullList: res.response[this.state.selectedIndex].list,
                            ordersList: res.response,
                            currentWeeksList: _daysArr,
                            weeksList: _daysArr,
                            dayListArr: _dates,
                            dayListArrDelivery: [],
                            dayOffset: 0
                        }, () => {
                            getOrder(_user.api_token, this.state.selectedOrderId, _user.id).then((res2) => {

                                var _dates = [];
                                var _dates2 = [];
                                var _daysArr = [];
                                var _index = 0;
                                var selectedStartDeliveryDate = res2.response[0].start_date;
                                //var d = getDateWithOffsetAllowed2(this.state.dayOffset, this.state.selectedStartDeliveryDate)[0].value;
                                var d = getDateWithOffsetAllowed2(this.state.dayOffset, convertStringToDateForSending(selectedStartDeliveryDate))[0].value;

                                let dateRange = generateDatesIntervalArray(new Date(res2.response[0].start_date), new Date(res2.response[0].end_date));

                                let deliveryDatesArray = generateDatesIntervalArray(new Date(res2.response[0].start_date), new Date(res2.response[0].end_date));
                                deliveryDatesArray.unshift(getLastDeliveredDay(deliveryDatesArray[0]));
                                var _index2 = 0;
                                let startId = res2.response[0].list[0].id;
                                deliveryDatesArray.map((item, index) => { 
                                    if (
                                        (res2.response[0].days - res2.response[0].days_offset ) > _index2 && 
                                        (
                                            item.getDay() == 0  || 
                                            item.getDay() == 2 || 
                                            item.getDay() == 4
                                        )
                                    ) {
                                        _dates2.push({
                                            value: startId,
                                            date: item,
                                            weekDay: getWeekTitleByNum(getDay(item)),
                                            calDay: formatDate2(item),
                                        });
                                        startId++;
                                        _index2++;
                                    }
                                })



                                res2.response[0].list.map((item, index) => {

                                    if (
                                        (
                                            res2.response[0].days - res2.response[0].days_offset
                                        ) > _index
                                    ) {

                                        _index++;
                                        _daysArr.push({
                                            id: item.id,
                                            day: formatDate2(item.date),
                                            dayName: getWeekTitleByNum(getDay(item.date)),
                                            date: item.date,
                                            active: false,
                                            editMenu: this._hasChange(item),
                                            disabled: false
                                        });
                                        _dates.push({
                                            value: item.id,
                                            date: item.date,
                                            weekDay: getWeekTitleByNum(getDay(item.date)),
                                            calDay: formatDate2(item.date),
                                            dinner1_may_change: item.dinner1_may_change,
                                            dinner2_may_change: item.dinner2_may_change,
                                            dinner3_may_change: item.dinner3_may_change,
                                            dinner4_may_change: item.dinner4_may_change,
                                            dinner5_may_change: item.dinner5_may_change,
                                            dinner1: item.dinner1,
                                            dinner2: item.dinner2,
                                            dinner3: item.dinner3,
                                            dinner4: item.dinner4,
                                            dinner5: item.dinner5
                                        });
                                    }
                                })

                                //exclude if programs size Pn-Pt
                                _daysArr = this._excludeDayOff(_daysArr, this.state.programSize);
                                let _weekList = this._excludeDates(
                                    _daysArr,
                                    excludeDateStart,
                                    excludeDateP1End
                                  );
                                let _weekListEx = this._excludeDayOff(_weekList, this.state.programSize);
                                //Нужно перенести даты повторно т.к. мы исключили выходные 
                                let _weekListEx2 = this._excludeDates(
                                    _weekListEx,
                                    excludeDateStart,
                                    excludeDateP1End
                                  ); 
                                console.log("weeksList", _weekList, _weekListEx, _weekListEx2 );

                             
                             

                                let _dayListArrDeliveryEx = this._excludeDeliveryDates(
                                    _dates2,
                                    _weekListEx2,
                                    excludeDateStart,
                                    excludeDateP1End,
                                    this.state.programSize
                                ); 
                                console.log("_dayListArrDeliveryEx",  
                                     _dates2, 
                                    
                                    excludeDateStart,
                                    excludeDateP1End,
                                    this.state.programSize,
                                    _dayListArrDeliveryEx
                                );

                                //add first deliovered day 
                                if (_dates[0]) this.setState({ activeDay: _dates[0] });
                                this.setState({
                                    currentWeeksList: _daysArr,
                                    weeksList: _weekListEx2,
                                    dayListArr: _dates,
                                    dayListArrDelivery: _dayListArrDeliveryEx,
                                    selectedOrder: res2.response[0],
                                    changeDeliveryList: res2.response[0].deliveries,
                                    changeUserList: res2.response[0].replacments,
                                    changeEshopList: res2.response[0].additionals,
                                }, () => { if (_showModalProlongate) this._showProlongationModal(this.state.selectedIndex) })
                            })
                        });
                    } else {
                        this.setState({ loader: false })
                    }

                } else {
                    saveUser(null);
                    this.props.history.push("/login");
                }

            }).catch((res) => {
                saveUser(null);
                this.props.history.push("/login");
            })
        }
    }

    render() {
        var topAutoPlanList = this.state.prolongationList.map((item, index) => {
            return (
                <div key={index}>

                    <button
                        className={"plan-tab"}
                        onClick={() => {
                            this._stopProlongatePlan(item.id)
                        }}
                    >
                        <strong>#{item.plan_id} <b>🗑</b> </strong>
                    </button>

                </div>

            )
        })

        var topPlanList = this.state.ordersList.map((item, index) => {
            return (
                <div key={index}>

                    <button

                        className={"plan-tab" + (this.state.activePlanTab == index ? " active" : "")}
                        onClick={() => this.setState({
                            activePlanTab: index,
                            selectedIndex: index,
                        }, () => {
                            this._load();
                        })}
                    >
                        <div className="plan-tab-img">
                            <img src={require('../../assets/imgs/menu-type/healthy-gray.svg')} />
                            <img src={require('../../assets/imgs/menu-type/healthy-white.svg')} />
                        </div>
                        <h4>{translateObject(item.program, 'title', this.state.lang)}  #{item.id}</h4>

                        {item.is_pay == 0 ? <p style={{ color: '#bd2a67' }}>{global.locale.plans_not_paid}</p> : null}
                        <p>{translateObject(item.calories, 'title', this.state.lang)} ({item.calories.from_cal} - {item.calories.to_cal})</p>
                        <strong>{global.locale.plans_not_paid_from} {formatDate2Plus(item.start_date, 0)} {global.locale.plans_not_paid_for} {formatDate2Plus(item.end_date, 0)}</strong>

                        <strong>{item.program_size == 0 ? global.locale.home_food_menu_option_4_item_1 : global.locale.home_food_menu_option_4_item_2}  </strong>


                    </button>

                    {item.is_pay == 0 ?
                        <div className='btn-wrapper green-button-outline' style={{ width: 150 }}>
                            <div class="btn-back"><div class="btn-shadow black-shadow"></div></div>
                            <button
                                className={"plan-tab-btn btn btn-sm btn-green-outline"}
                                onClick={() => {
                                    this._pay(index);
                                }}
                                style={{ padding: 4 }}
                            >
                                <strong>{global.locale.cart_btn_paid2}</strong>
                            </button>
                        </div>
                        : null}
                    {this.state.user.id == 1556 ?
                        <div className='btn-wrapper green-button' style={{ width: 150, marginTop: 10 }}>
                            <div class="btn-back"><div class="btn-shadow black-shadow"></div></div>
                            <button
                                className={"plan-tab-btn btn btn-sm btn-green"}
                                onClick={() => {
                                    this._replay(index);
                                }}
                                style={{ padding: 4 }}
                            >
                                <img src={subIconActive} /> <strong> {global.locale.plans_replay}</strong>
                            </button>
                        </div>
                        : null}

                    <div className='btn-wrapper green-button' style={{ width: 150, marginTop: 10 }}>
                        <div class="btn-back"><div class="btn-shadow black-shadow"></div></div>
                        <button
                            className={"plan-tab-btn btn btn-sm btn-green"}
                            onClick={() => {
                                this._replay(index);
                            }}
                            style={{ padding: 4 }}
                        >
                            <img src={subIconActive} /> <strong> {global.locale.plans_replay}</strong>
                        </button>
                    </div>

                    {this.state.user.id == 1556 ?
                        <div className='btn-wrapper' style={{ width: 150, marginTop: 10 }}>
                            <UiSwitch
                                label={global.locale.plans_prolongation_btn}
                                labelActive={global.locale.plans_prolongation_btn}
                                selectValue={this._checkProlongate(item.id)}
                                callBack={(val) => {
                                    if (!val) {
                                        this.setState({
                                            activePlanTab: index,
                                            selectedIndex: index,
                                        }, () => {
                                            this._load(true)
                                        })
                                    } else this._pausePlan(item.id);
                                }}
                            />
                        </div>
                        : null}
                </div>
            )
        })

        var deliveryItemsList = this.state.dayListArrDelivery.map((item, index) => {

            if ((0 == getDay(item.date) || 2 == getDay(item.date) || 4 == getDay(item.date))) {
                return (
                    <UiDeliveryItem
                        key={index}
                        date={item.calDay}
                        day={item.calDay + ", " + item.weekDay}

                        dayTime={!this._checkChangeDelivery(item.date) ? this.state.user.ftdaypart : this._checkChangeDelivery(item.date).ftdaypart}
                        timeRange={!this._checkChangeDelivery(item.date) ? this.state.user.fttime : this._checkChangeDelivery(item.date).fttime}
                        adress={(!this._checkChangeDelivery(item.date) ? this.state.user.city : this._checkChangeDelivery(item.date).city) + ", " + (!this._checkChangeDelivery(item.date) ? this.state.user.street : this._checkChangeDelivery(item.date).street)}
                        comment={(!this._checkChangeDelivery(item.date) ? this.state.user.comment : this._checkChangeDelivery(item.date).comment)}

                        callBackInit={() => {
                            this.setState({
                                showChangeAdress: true,
                                selectedChangeAdressDay: formatDateYMD(item.date)
                            }, () => {
                                this.initialize();
                            })
                        }}
                    />
                )
            }

        })

        var reviews = this.state.reviewList.map((item, index) => {
            return (
                <div key={index} className="col-xl-4 col-lg-6">
                    <UiPlanCard
                        addItem={false}
                        image={{ uri: item.dinner.image }}

                        title={item.dinner.title_ch}
                        ingredient={item.dinner.description_ch}

                        lanchType={global.locale.plans_dinner}
                        about={item.dinner.calories + " " + global.locale.plans_kkal + ", " + item.dinner.weight + " " + global.locale.plans_gramm}
                        ccal={item.dinner.calories}
                        protein={item.dinner.protein}
                        fats={item.dinner.fats}
                        carbo={item.dinner.carbohydrates}


                        rating={item.full_raiting}
                        callBackRating={(val) => {
                            this._addRating(item.dinner.id, val)
                        }}
                    />
                </div>
            )
        })

        return (
            <div ref={this.divPage} id="page">
                <UiLoader show={this.state.loader} />
                <UiAlert
                    show={this.state.showAlert}
                    title={this.state.alertTitle}
                    text={this.state.alertText}
                    callBack={() => this.setState({ showAlert: false })}
                    leftBtnText="Ок"
                />

                <UiModalMenu
                    lang={this.state.lang}
                    show={this.state.showMenu}
                    menu={this.state.menuDinners}
                    callBackClose={() => this.setState({ showMenu: false })}
                    callBackChange={(_to, _to_item) => this._addChange(this.state.activeDay.value, this.state.changeItemBeforeId, _to, _to_item)}
                />
                <UiModalMenu
                    lang={this.state.lang}
                    show={this.state.showEMenu}
                    menu={this.state.menuEShop}
                    callBackClose={() => this.setState({ showEMenu: false })}
                    callBackChange={(_to, _to_item) => this._addChangeEshop(this.state.activeDay.value, _to, _to_item)}
                />

                <UiModalChangeAdress
                    show={this.state.showChangeAdress}
                    selectedFTTime={this.state.selectedFTTime}
                    firstTimeArr={this.state.firstTimeArr}
                    closeCallBack={() => this.setState({ showChangeAdress: false })}
                    callBack={(dp, time, comment, city, street, code) => {
                        this.setState({ showChangeAdress: false });
                        if (city.length > 0 && street.length > 0) {
                            this._addChangeDelivery(this.state.selectedChangeAdressDay, city, street, code, dp, time, comment);
                        } else {
                            this._alert(global.locale.plans_alert_save_err);
                        }
                    }}
                />
                <UiModalPay
                    show={this.state.showPayModal}
                    title={global.locale.plans_pay_title_paid}
                    description={global.locale.plans_pay_subtitle_paid}
                    price={this.state.replacmentsPrice}
                    bonus={0}
                    callBackClose={() => this.setState({ showPayModal: false })}
                    callBack={(type, bonus) => {
                        this._pay(type, bonus);
                    }}
                />

                <UiModalTransfer
                    show={this.state.showTransferModal}
                    title={global.locale.plans_prolongation_title}
                    description={global.locale.plans_prolongation_btn}
                    minDate={this.state.selectedMinPlanDate}
                    days={this.state.newPlanDaysCount}
                    programSize={this.state.newPlanProgramSize}
                    callBackClose={() => {
                        this.setState({ showTransferModal: false })
                        this._load();
                    }}
                    callBack={(date, count) => {
                        this.setState({ showTransferModal: false })
                        this._prolongatePlan(date, count);

                    }}
                />


                <div className={"wrapper login-wrapper" + (this.state.showMenuBar ? ' scroll' : '')}>
                    <div className="content">
                        <UiHeader profile menuHeight={this.state.menuHeight} langCallBack={(val) => this.changeLang(val)} />
                        <section className="profile-title image-bg small-section">
                            <div className="image-shadow">
                                <div className="container">
                                    <h2 className="section-title">{global.locale.plans_header}</h2>
                                </div>
                            </div>
                        </section>

                        <section className="profile-wrap">
                            <div className="container">
                                <div className="row">
                                    <div className="col-lg-4 col-xl-3">
                                        <UiMenuProfile activeItem={2} callBack={(val) => {
                                            if (val == 0) this.props.history.push("/profile");
                                            if (val == 1) this.props.history.push("/cart");
                                            if (val == 2) this.props.history.push("/plans");
                                            if (val == 3) this.props.history.push("/subscribe");
                                            if (val == 4) this.props.history.push("/history");

                                            if (val == 5) this.props.history.push("/bonus");
                                        }} />
                                    </div>

                                    <div className="col-md-12 col-lg-8 col-xl-9">
                                        <div className="row">
                                            <div className="col-md-12">
                                                {this.state.ordersList.length > 0 ?
                                                    <div className="plans-wrap">
                                                        <h3 className="form-title">{global.locale.plans_title}</h3>
                                                        <div className="plans-tab-bar">
                                                            {topPlanList}
                                                        </div>
                                                        <div className="plan-cards-list">
                                                            <div className="order-tabs">
                                                                <button onClick={() => this.setState({ activeOrderTab: 0 })} className={"order-tab" + (this.state.activeOrderTab == 0 ? " active" : "")}>
                                                                    <img src={menuIcon} />
                                                                    <span>{global.locale.plans_li_1}</span>
                                                                </button>
                                                                <button onClick={() => this.setState({ activeOrderTab: 1 })} className={"order-tab" + (this.state.activeOrderTab == 1 ? " active" : "")}>
                                                                    <img src={deliveryIcon} />
                                                                    <span>{global.locale.plans_li_2}</span>
                                                                </button>
                                                                <button onClick={() => this.setState({ activeOrderTab: 2 })} className={"order-tab" + (this.state.activeOrderTab == 2 ? " active" : "")}>
                                                                    <img src={reviewIcon} />
                                                                    <span>{global.locale.plans_li_3}</span>
                                                                </button>
                                                            </div>

                                                            <UiCalendar
                                                                weeksList={this.state.weeksList}
                                                                callBack={(val) => {
                                                                    console.log(val);
                                                                    this._getItemByDay(val.id);
                                                                }}
                                                            />
                                                            <div className="plan-cards-change">
                                                                <img src={changeIcon} />
                                                                <span>- {global.locale.plans_info}</span>
                                                            </div>
                                                            <div className="cards-list">
                                                                <div className="row">
                                                                    <div className="col-md-12">
                                                                        {this.state.activeOrderTab == 0 ?
                                                                            <div>

                                                                                <div className="plan-day-info">
                                                                                    {this.state.activeDay.weekDay ?
                                                                                        <p>{global.locale.plans_summary_in} {this.state.activeDay.weekDay}: <span>{this._getTotalMetrics(this.state.activeDay).ck} {global.locale.plans_kkal}</span> | {global.locale.plans_B}:{this._getTotalMetrics(this.state.activeDay).p}  {global.locale.plans_J}:{this._getTotalMetrics(this.state.activeDay).f}  {global.locale.plans_U}:{this._getTotalMetrics(this.state.activeDay).c} </p>
                                                                                        : null}
                                                                                </div>

                                                                                <div className="row">
                                                                                    
                                                                                    {/*(
                                                                                        this.state.activeDay.dinner1 != null ||
                                                                                        this.state.activeDay.dinner2 != null ||
                                                                                        this.state.activeDay.dinner3 != null ||
                                                                                        this.state.activeDay.dinner4 != null ||
                                                                                        this.state.activeDay.dinner5 != null
                                                                                    ) ?
                                                                                        <div className="col-xl-4 col-lg-6">
                                                                                            {!this._checkChangeEshop(this.state.activeDay.value) ?
                                                                                                <UiPlanCard
                                                                                                    addItem={true}
                                                                                                    lanchType={global.locale.plans_drink}
                                                                                                    addEScallBack={() => this.setState({ showEMenu: true })}
                                                                                                />
                                                                                                :
                                                                                                <UiPlanCard
                                                                                                    addItem={false}
                                                                                                    image={{ uri: this._checkChangeEshop(this.state.activeDay.value).image }}
                                                                                                    day={this.state.activeDay.weekDay}

                                                                                                    title={this._checkChangeEshop(this.state.activeDay.value).title_ru}
                                                                                                    ingredient={this._checkChangeEshop(this.state.activeDay.value).description_ru}

                                                                                                    lanchType={"+ " + this._checkChangeEshop(this.state.activeDay.value).price + " " + global.locale.plans_currency}
                                                                                                    about={this._checkChangeEshop(this.state.activeDay.value).calories + " " + global.locale.plans_kkal + ", " + (this._checkChangeEshop(this.state.activeDay.value).weight) + " " + global.locale.plans_gramm}
                                                                                                    ccal={this._checkChangeEshop(this.state.activeDay.value).calories}
                                                                                                    protein={this._checkChangeEshop(this.state.activeDay.value).protein}
                                                                                                    fats={this._checkChangeEshop(this.state.activeDay.value).fats}
                                                                                                    carbo={this._checkChangeEshop(this.state.activeDay.value).carbohydrates}

                                                                                                    hasChange={true}
                                                                                                    changeCallBack={() => {
                                                                                                        this.setState({ showEMenu: true })
                                                                                                    }}
                                                                                                    hasRaiting={this._checkChangeEshop(this.state.activeDay.value).hasRaiting}

                                                                                                    rating={this._checkChangeEshop(this.state.activeDay.value).raiting}
                                                                                                    callBackRating={(val) => {
                                                                                                        this._addRating(this._checkChangeEshop(this.state.activeDay.value).id, val)
                                                                                                    }}

                                                                                                />
                                                                                            }
                                                                                        </div>
                                                                                        : null */}

                                                                                    {this.state.activeDay.dinner1 && (this.state.selectedOrder.size == 0 || this.state.selectedOrder.size == 1 || this.state.selectedOrder.size == 2 || this.state.selectedOrder.size == 4) ?
                                                                                        <div className="col-xl-4 col-lg-6">

                                                                                            <UiPlanCard
                                                                                                addItem={false}
                                                                                                image={{ uri: !this._checkChange(this.state.activeDay.value, 0) ? (this.state.activeDay.dinner1[0]).image : this._checkChange(this.state.activeDay.value, 0).image }}
                                                                                                day={this.state.activeDay.weekDay}


                                                                                                title={!this._checkChange(this.state.activeDay.value, 0) ? this.locale((this.state.activeDay.dinner1[0]), "title") : this.locale(this._checkChange(this.state.activeDay.value, 0), "title")}
                                                                                                ingredient={!this._checkChange(this.state.activeDay.value, 0) ? this.locale((this.state.activeDay.dinner1[0]), "description") : this.locale(this._checkChange(this.state.activeDay.value, 0), "description")}


                                                                                                lanchType={global.locale.home_food_menu_text_3}
                                                                                                about={!this._checkChange(this.state.activeDay.value, 0) ? (this.state.activeDay.dinner1[0]).calories + " " + global.locale.plans_kkal : this._checkChange(this.state.activeDay.value, 0).calories + " " + global.locale.plans_kkal + ", " + (!this._checkChange(this.state.activeDay.value, 0) ? (this.state.activeDay.dinner1[0]).weight : this._checkChange(this.state.activeDay.value, 0).weight) + " " + global.locale.plans_gramm}
                                                                                                ccal={!this._checkChange(this.state.activeDay.value, 0) ? (this.state.activeDay.dinner1[0]).calories : this._checkChange(this.state.activeDay.value, 0).calories}
                                                                                                protein={!this._checkChange(this.state.activeDay.value, 0) ? (this.state.activeDay.dinner1[0]).protein : this._checkChange(this.state.activeDay.value, 0).protein}
                                                                                                fats={!this._checkChange(this.state.activeDay.value, 0) ? (this.state.activeDay.dinner1[0]).fats : this._checkChange(this.state.activeDay.value, 0).fats}
                                                                                                carbo={!this._checkChange(this.state.activeDay.value, 0) ? (this.state.activeDay.dinner1[0]).carbohydrates : this._checkChange(this.state.activeDay.value, 0).carbohydrates}

                                                                                                hasChange={this.state.activeDay.dinner1_may_change == 1}
                                                                                                changeCallBack={() => {
                                                                                                    this._getAdditional(0);
                                                                                                }}

                                                                                                hasRaiting={!this._checkChange(this.state.activeDay.value, 0) ? (this.state.activeDay.dinner1[0]).hasRaiting : this._checkChange(this.state.activeDay.value, 0).hasRaiting}
                                                                                                rating={!this._checkChange(this.state.activeDay.value, 0) ? (this.state.activeDay.dinner1[0]).raiting : this._checkChange(this.state.activeDay.value, 0).raiting}
                                                                                                callBackRating={(val) => {
                                                                                                    this._addRating(!this._checkChange(this.state.activeDay.value, 0) ? (this.state.activeDay.dinner1[0]).id : this._checkChange(this.state.activeDay.value, 0).id, val)
                                                                                                }}
                                                                                            />

                                                                                        </div>
                                                                                        : null}
                                                                                    {this.state.activeDay.dinner2 && (this.state.selectedOrder.size == 0) ?
                                                                                        <div className="col-xl-4 col-lg-6">
                                                                                            <UiPlanCard
                                                                                                addItem={false}
                                                                                                image={{ uri: !this._checkChange(this.state.activeDay.value, 1) ? (this.state.activeDay.dinner2[0]).image : this._checkChange(this.state.activeDay.value, 1).image }}
                                                                                                day={this.state.activeDay.weekDay}

                                                                                                title={!this._checkChange(this.state.activeDay.value, 1) ? this.locale((this.state.activeDay.dinner2[0]), "title") : this.locale(this._checkChange(this.state.activeDay.value, 1), "title")}
                                                                                                ingredient={!this._checkChange(this.state.activeDay.value, 1) ? this.locale((this.state.activeDay.dinner2[0]), "description") : this.locale(this._checkChange(this.state.activeDay.value, 1), "description")}



                                                                                                lanchType={global.locale.home_food_menu_text_4}
                                                                                                about={!this._checkChange(this.state.activeDay.value, 1) ? (this.state.activeDay.dinner2[0]).calories + " " + global.locale.plans_kkal : this._checkChange(this.state.activeDay.value, 1).calories + " " + global.locale.plans_kkal + ", " + (!this._checkChange(this.state.activeDay.value, 1) ? (this.state.activeDay.dinner2[0]).weight : this._checkChange(this.state.activeDay.value, 1).weight) + " " + global.locale.plans_gramm}
                                                                                                ccal={!this._checkChange(this.state.activeDay.value, 1) ? (this.state.activeDay.dinner2[0]).calories : this._checkChange(this.state.activeDay.value, 1).calories}
                                                                                                protein={!this._checkChange(this.state.activeDay.value, 1) ? (this.state.activeDay.dinner2[0]).protein : this._checkChange(this.state.activeDay.value, 1).protein}
                                                                                                fats={!this._checkChange(this.state.activeDay.value, 1) ? (this.state.activeDay.dinner2[0]).fats : this._checkChange(this.state.activeDay.value, 1).fats}
                                                                                                carbo={!this._checkChange(this.state.activeDay.value, 1) ? (this.state.activeDay.dinner2[0]).carbohydrates : this._checkChange(this.state.activeDay.value, 1).carbohydrates}

                                                                                                hasChange={this.state.activeDay.dinner2_may_change == 1}
                                                                                                changeCallBack={() => {
                                                                                                    this._getAdditional(1);
                                                                                                }}

                                                                                                hasRaiting={!this._checkChange(this.state.activeDay.value, 1) ? (this.state.activeDay.dinner2[0]).hasRaiting : this._checkChange(this.state.activeDay.value, 1).hasRaiting}

                                                                                                rating={!this._checkChange(this.state.activeDay.value, 1) ? (this.state.activeDay.dinner2[0]).raiting : this._checkChange(this.state.activeDay.value, 1).raiting}
                                                                                                callBackRating={(val) => {
                                                                                                    this._addRating(!this._checkChange(this.state.activeDay.value, 1) ? (this.state.activeDay.dinner2[0]).id : this._checkChange(this.state.activeDay.value, 1).id, val)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        : null}
                                                                                    {this.state.activeDay.dinner3 && (this.state.selectedOrder.size == 0 || this.state.selectedOrder.size == 1 || this.state.selectedOrder.size == 3 || this.state.selectedOrder.size == 4) ?
                                                                                        <div className="col-xl-4 col-lg-6">

                                                                                            <UiPlanCard
                                                                                                addItem={false}
                                                                                                image={{ uri: !this._checkChange(this.state.activeDay.value, 2) ? (this.state.activeDay.dinner3[0]).image : this._checkChange(this.state.activeDay.value, 2).image }}
                                                                                                day={this.state.activeDay.weekDay}

                                                                                                title={!this._checkChange(this.state.activeDay.value, 2) ? this.locale((this.state.activeDay.dinner3[0]), "title") : this.locale(this._checkChange(this.state.activeDay.value, 2), "title")}
                                                                                                ingredient={!this._checkChange(this.state.activeDay.value, 2) ? this.locale((this.state.activeDay.dinner3[0]), "description") : this.locale(this._checkChange(this.state.activeDay.value, 2), "description")}

                                                                                                lanchType={global.locale.home_food_menu_text_5}
                                                                                                about={!this._checkChange(this.state.activeDay.value, 2) ? (this.state.activeDay.dinner3[0]).calories + " " + global.locale.plans_kkal : this._checkChange(this.state.activeDay.value, 2).calories + " " + global.locale.plans_kkal + ", " + (!this._checkChange(this.state.activeDay.value, 2) ? (this.state.activeDay.dinner3[0]).weight : this._checkChange(this.state.activeDay.value, 2).weight) + " " + global.locale.plans_gramm}
                                                                                                ccal={!this._checkChange(this.state.activeDay.value, 2) ? (this.state.activeDay.dinner3[0]).calories : this._checkChange(this.state.activeDay.value, 2).calories}
                                                                                                protein={!this._checkChange(this.state.activeDay.value, 2) ? (this.state.activeDay.dinner3[0]).protein : this._checkChange(this.state.activeDay.value, 2).protein}
                                                                                                fats={!this._checkChange(this.state.activeDay.value, 2) ? (this.state.activeDay.dinner3[0]).fats : this._checkChange(this.state.activeDay.value, 2).fats}
                                                                                                carbo={!this._checkChange(this.state.activeDay.value, 2) ? (this.state.activeDay.dinner3[0]).carbohydrates : this._checkChange(this.state.activeDay.value, 2).carbohydrates}

                                                                                                hasChange={this.state.activeDay.dinner3_may_change == 1}
                                                                                                changeCallBack={() => {
                                                                                                    this._getAdditional(2);
                                                                                                }}
                                                                                                hasRaiting={!this._checkChange(this.state.activeDay.value, 2) ? (this.state.activeDay.dinner3[0]).hasRaiting : this._checkChange(this.state.activeDay.value, 2).hasRaiting}

                                                                                                rating={!this._checkChange(this.state.activeDay.value, 2) ? (this.state.activeDay.dinner3[0]).raiting : this._checkChange(this.state.activeDay.value, 2).raiting}
                                                                                                callBackRating={(val) => {
                                                                                                    this._addRating(!this._checkChange(this.state.activeDay.value, 2) ? (this.state.activeDay.dinner3[0]).id : this._checkChange(this.state.activeDay.value, 2).id, val)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        : null}
                                                                                    {this.state.activeDay.dinner4 && (this.state.selectedOrder.size == 0) ?
                                                                                        <div className="col-xl-4 col-lg-6">
                                                                                            <UiPlanCard
                                                                                                addItem={false}
                                                                                                image={{ uri: !this._checkChange(this.state.activeDay.value, 3) ? (this.state.activeDay.dinner4[0]).image : this._checkChange(this.state.activeDay.value, 3).image }}
                                                                                                day={this.state.activeDay.weekDay}

                                                                                                title={!this._checkChange(this.state.activeDay.value, 3) ? this.locale((this.state.activeDay.dinner4[0]), "title") : this.locale(this._checkChange(this.state.activeDay.value, 3), "title")}
                                                                                                ingredient={!this._checkChange(this.state.activeDay.value, 3) ? this.locale((this.state.activeDay.dinner4[0]), "description") : this.locale(this._checkChange(this.state.activeDay.value, 3), "description")}

                                                                                                lanchType={global.locale.home_food_menu_text_6}
                                                                                                about={!this._checkChange(this.state.activeDay.value, 3) ? (this.state.activeDay.dinner4[0]).calories + " " + global.locale.plans_kkal : this._checkChange(this.state.activeDay.value, 3).calories + " " + global.locale.plans_kkal + ", " + (!this._checkChange(this.state.activeDay.value, 3) ? (this.state.activeDay.dinner4[0]).weight : this._checkChange(this.state.activeDay.value, 3).weight) + " " + global.locale.plans_gramm}
                                                                                                ccal={!this._checkChange(this.state.activeDay.value, 3) ? (this.state.activeDay.dinner4[0]).calories : this._checkChange(this.state.activeDay.value, 3).calories}
                                                                                                protein={!this._checkChange(this.state.activeDay.value, 3) ? (this.state.activeDay.dinner4[0]).protein : this._checkChange(this.state.activeDay.value, 3).protein}
                                                                                                fats={!this._checkChange(this.state.activeDay.value, 3) ? (this.state.activeDay.dinner4[0]).fats : this._checkChange(this.state.activeDay.value, 3).fats}
                                                                                                carbo={!this._checkChange(this.state.activeDay.value, 3) ? (this.state.activeDay.dinner4[0]).carbohydrates : this._checkChange(this.state.activeDay.value, 3).carbohydrates}

                                                                                                hasChange={this.state.activeDay.dinner4_may_change == 1}
                                                                                                changeCallBack={() => {
                                                                                                    this._getAdditional(3);
                                                                                                }}
                                                                                                hasRaiting={!this._checkChange(this.state.activeDay.value, 3) ? (this.state.activeDay.dinner4[0]).hasRaiting : this._checkChange(this.state.activeDay.value, 3).hasRaiting}

                                                                                                rating={!this._checkChange(this.state.activeDay.value, 3) ? (this.state.activeDay.dinner4[0]).raiting : this._checkChange(this.state.activeDay.value, 3).raiting}
                                                                                                callBackRating={(val) => {
                                                                                                    this._addRating(!this._checkChange(this.state.activeDay.value, 3) ? (this.state.activeDay.dinner4[0]).id : this._checkChange(this.state.activeDay.value, 3).id, val)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        : null}
                                                                                    {this.state.activeDay.dinner5 && (this.state.selectedOrder.size == 0 || this.state.selectedOrder.size == 2 || this.state.selectedOrder.size == 3 || this.state.selectedOrder.size == 4) ?
                                                                                        <div className="col-xl-4 col-lg-6">

                                                                                            <UiPlanCard
                                                                                                addItem={false}
                                                                                                image={{ uri: !this._checkChange(this.state.activeDay.value, 4) ? (this.state.activeDay.dinner5[0]).image : this._checkChange(this.state.activeDay.value, 4).image }}
                                                                                                day={this.state.activeDay.weekDay}

                                                                                                title={!this._checkChange(this.state.activeDay.value, 4) ? this.locale((this.state.activeDay.dinner5[0]), "title") : this.locale(this._checkChange(this.state.activeDay.value, 4), "title")}
                                                                                                ingredient={!this._checkChange(this.state.activeDay.value, 4) ? this.locale((this.state.activeDay.dinner5[0]), "description") : this.locale(this._checkChange(this.state.activeDay.value, 4), "description")}

                                                                                                lanchType={global.locale.home_food_menu_text_7}
                                                                                                about={!this._checkChange(this.state.activeDay.value, 4) ? (this.state.activeDay.dinner5[0]).calories + " " + global.locale.plans_kkal : this._checkChange(this.state.activeDay.value, 4).calories + " " + global.locale.plans_kkal + ", " + (!this._checkChange(this.state.activeDay.value, 4) ? (this.state.activeDay.dinner5[0]).weight : this._checkChange(this.state.activeDay.value, 4).weight) + " " + global.locale.plans_gramm}
                                                                                                ccal={!this._checkChange(this.state.activeDay.value, 4) ? (this.state.activeDay.dinner5[0]).calories : this._checkChange(this.state.activeDay.value, 4).calories}
                                                                                                protein={!this._checkChange(this.state.activeDay.value, 4) ? (this.state.activeDay.dinner5[0]).protein : this._checkChange(this.state.activeDay.value, 4).protein}
                                                                                                fats={!this._checkChange(this.state.activeDay.value, 4) ? (this.state.activeDay.dinner5[0]).fats : this._checkChange(this.state.activeDay.value, 4).fats}
                                                                                                carbo={!this._checkChange(this.state.activeDay.value, 4) ? (this.state.activeDay.dinner5[0]).carbohydrates : this._checkChange(this.state.activeDay.value, 4).carbohydrates}

                                                                                                hasChange={this.state.activeDay.dinner5_may_change == 1}
                                                                                                changeCallBack={() => {
                                                                                                    this._getAdditional(4);
                                                                                                }}

                                                                                                hasRaiting={!this._checkChange(this.state.activeDay.value, 4) ? (this.state.activeDay.dinner5[0]).hasRaiting : this._checkChange(this.state.activeDay.value, 4).hasRaiting}
                                                                                                rating={!this._checkChange(this.state.activeDay.value, 4) ? (this.state.activeDay.dinner5[0]).raiting : this._checkChange(this.state.activeDay.value, 4).raiting}
                                                                                                callBackRating={(val) => {
                                                                                                    this._addRating(!this._checkChange(this.state.activeDay.value, 4) ? (this.state.activeDay.dinner5[0]).id : this._checkChange(this.state.activeDay.value, 4).id, val)
                                                                                                }}
                                                                                            />
                                                                                        </div>
                                                                                        : null}



                                                                                </div>

                                                                            </div>
                                                                            : null
                                                                        }
                                                                        {this.state.activeOrderTab == 1 ?
                                                                            <div className="delivery-wrap">
                                                                                {deliveryItemsList}
                                                                            </div>
                                                                            : null
                                                                        }

                                                                        {this.state.activeOrderTab == 2 ?
                                                                            <div className="row">
                                                                                {reviews}
                                                                            </div>
                                                                            : null
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <p>{global.locale.plans_text}</p>
                                                    </div>
                                                    :
                                                    <div className="empty">
                                                        <img className="empty-img" src={planEmptyImg} />
                                                        <div className="empty-info">
                                                            <h4>{global.locale.plans_empty_orders}</h4>
                                                            <UiBtnGreen btnText={global.locale.plans_btn_go_to_menu} link href="/#menu" />
                                                        </div>
                                                    </div>
                                                }
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </section>
                        <UiFooter />
                    </div>
                </div>
            </div>
        );
    }
}

export default PlansPage;