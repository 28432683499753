import React, { Component } from "react";

import menuIcon from "../../assets/imgs/profile/dinner.svg";
import deliveryIcon from "../../assets/imgs/profile/delivery.svg";
import deliveryGreenIcon from "../../assets/imgs/profile/delivery-green.svg";
import reviewIcon from "../../assets/imgs/profile/heart.svg";

import changeIcon from "../../assets/imgs/profile/adjust.svg";
import infoIcon from "../../assets/imgs/ui/information.svg";
import planEmptyImg from "../../assets/imgs/profile/harvest.svg";

import UiAlert from "../../components/ui/modals/UiAlert.js";
import UiBtnGreen from "../../components/ui/buttons/UiBtnGreen.js";
import UiBtnPinkOutline from "../../components/ui/buttons/UiBtnPinkOutline.js";
import UiCalendar from "../../components/ui/tabs/UiCalendar.js";
import UiDocItem from "../../components/ui/cards/UiDocItem.js";
import UiHeader from "../../components/ui/header/UiHeader.js";
import UiFooter from "../../components/ui/footer/UiFooter.js";
import UiModalChangeAdress from "../../components/ui/modals/UiModalChangeAdress.js";
import UiModalPay from "../../components/ui/modals/UiModalPay.js";
import UiModalMenu from "../../components/ui/modals/UiModalMenu.js";
import UiPlanCard from "../../components/ui/cards/UiPlanCard.js";
import UiDeliveryItem from "../../components/ui/cards/UiDeliveryItem";
import UiBtnGreenOutline from "../../components/ui/buttons/UiBtnGreenOutline";
import UiSwitch from "../../components/ui/forms/UiSwitch";
import UiSelect from "../../components/ui/forms/UiSelect.js";
import UiSelectScroll from "../../components/ui/forms/UiSelectScroll";
import UiSelectDate from "../../components/ui/forms/UiSelectDate";
import UiLoader from "../../components/ui/modals/UiLoader";
import UiMenuProfile from "../../components/ui/tabs/UiMenuProfile";

import { en } from "../../i18n/en.js";
import { ru } from "../../i18n/ru.js";
import { ch } from "../../i18n/ch.js";
import { translateObject } from "../../i18n/locale.js";

import { getDeliveryPrice, getExcludesPrice } from "../../services/System";
import {
  getPriceFromArray,
  getPriceFromArraySales,
  getPriceFromArrayEs,
  getPriceFromArraySalesEs,
} from "../../components/common/Calculate.js";
import { ValidateInput } from "../../components/common/Validator";
import {
  loadUser,
  saveUser,
  updateUser,
  getOrders,
  confirmOrder,
  payOrder,
  getProfile,
  checkPromo,
} from "../../services/Users";
import {
  retrieveData,
  storeData,
  checkDublePromo,
  addPromocodeToStorage,
} from "../../services/Storage";
import {
  getAllPlans,
  getDaysByCalories,
  getAdditionalDinner,
  getEShop,
} from "../../services/Plans.js";
import {
  convertStringToDateToRu,
  formatDateYMD,
  getFormatedCurrentDate,
  getWeekTitleByNum,
  getDay,
  formatDate2,
  convertStringToDate,
  convertStringToDateForSending,
  getDateWithOffsetAllowed,
  getDateWithOffsetAllowed2,
  getLastDeliveredDay,
  generateDatesIntervalArray,
  formatDate,
} from "../../components/common/Dates.js";

const AnyReactComponent = () => <div classNameName="marker"></div>;
const google = window.google;

 
//Чтобы не было ошибок, скрываем в системе: *дни доставок*: 22/12
//В январе Первая доставка будет в воскресение 05/01
const excludeDateP1Start = "2024-12-22";
const excludeDateStart = "2024-12-23";
 
//В январе Первая доставка будет в воскресение 05/01
const excludeDateP1End = "2025-01-05";
//день старта + 1 2023-01-05
const excludeDateEnd = "2025-01-06";
//день первой доставки в нововм году
const firstDDinNY = "2024-01-05";

const offsetStartDateConst = "2025-01-05";

const excludeCalendarDaysList = [
  new Date(2024, 11, 22),
  new Date(2024, 11, 24),
  new Date(2024, 11, 26),
  new Date(2024, 11, 29),
  new Date(2024, 11, 31),
  new Date(2025, 0, 2)
]
//убрать из дней программы
const excludeCalendarDaysList2 = [
  new Date(2024, 11, 22),
  new Date(2024, 11, 23),
  new Date(2024, 11, 24),
  new Date(2024, 11, 26),
  new Date(2024, 11, 27),
  new Date(2024, 11, 28),
  new Date(2024, 11, 29),
  new Date(2024, 11, 31),
  new Date(2025, 0, 1),
  new Date(2025, 0, 2),
  new Date(2024, 0, 3),
  new Date(2024, 0, 4),
]

class CartPage extends Component {
  state = {
    loader: false,
    showMenu: false,
    showPayModal: false,
    plansNum: 1,
    activePlanTab: 0,
    activeItem: 1,
    activeOrderTab: 0,
    menuHeight: 0,
    subscribeActiviti: 1,
    subscribeItem: 0,
    dayOffset: 0,
    daysIndex: 0,
    selectedStartDeliveryDate: getDateWithOffsetAllowed2(
      2,
      getFormatedCurrentDate()
    )[0].text,

    street: "",
    code: "",
    city: "",
    selectedFTTime: "17:00 - 22:00",
    selectedFirstDate: getDateWithOffsetAllowed2(2, getFormatedCurrentDate())[0]
      .text,
    selectedStartDate: getDateWithOffsetAllowed2(2, getFormatedCurrentDate())[0]
      .text,
    minDate: getDateWithOffsetAllowed2(2, getFormatedCurrentDate())[0].value,

    optionsListDays: [4, 6, 12, 24],
    optionsListDaysSmall: [5, 10, 15, 20],

    promoSale: 0,
    promocodeText: "",
    excludeDinnerPrice: 0,

    cart: null,
    firstTimeArr: [{ value: "17:00 - 22:00", text: "17:00 - 22:00" }],
    firstDateArr: getDateWithOffsetAllowed2(2, getFormatedCurrentDate()),
    excludeDinnerPriceList: [],
    deliveryPrices: [],
    excludeDaysArr: [],
    changeDeliveryList: [],
    changeUserList: [],
    changeEshopList: [],
    menuDinners: [],
    menuEShop: [],
    dayListArrDelivery: [],
    dayListArr: [],
    currentWeeksList: [],
    weeksList: [],
    itemsList: [],
    excludesList: [],

    user: {},
    activeDay: {
      dinner1: null,
      dinner2: null,
      dinner3: null,
      dinner4: null,
      dinner5: null,
    },
  };

  constructor(props) {
    super(props);
    let _lang = retrieveData("ikLocale");
    if (_lang == null) _lang = "ch";
    if (_lang == "ch") global.locale = ch;
    if (_lang == "ru") global.locale = ru;
    if (_lang == "en") global.locale = en;

    this.setState({ lang: _lang });
  }

  changeLang(val) {
    let _lang = "ch";
    if (val == 0) _lang = "ru";
    if (val == 1) _lang = "ch";
    if (val == 2) _lang = "en";
    if (_lang == "ch") global.locale = ch;
    if (_lang == "ru") global.locale = ru;
    if (_lang == "en") global.locale = en;
    this.setState({ lang: _lang });
    storeData("ikLocale", _lang);
    this._load();
  }

  locale(obj, val) {
    let line = "";
    if (val == "title") {
      if (this.state.lang == "ch") line = obj.title_ch;
      if (this.state.lang == "en") line = obj.title_en;
      if (this.state.lang == "ru") line = obj.title_ru;
    }
    if (val == "description") {
      if (this.state.lang == "ch") line = obj.description_ch;
      if (this.state.lang == "en") line = obj.description_en;
      if (this.state.lang == "ru") line = obj.description_ru;
    }
    if (val == "full_description") {
      if (this.state.lang == "ch") line = obj.full_description_ch;
      if (this.state.lang == "en") line = obj.full_description_en;
      if (this.state.lang == "ru") line = obj.full_description_ru;
    }

    return line;
  }

  componentDidMount() {
    let _lang = retrieveData("ikLocale");
    if (_lang == null) _lang = "ch";
    this.setState({ lang: _lang });

    this._load();
    this._checkDeliveryPrice();
    window.addEventListener("scroll", () => {
      //if (document.documentElement.scrollTop > 160) this.setState({ showMenuBar: true }); else this.setState({ showMenuBar: false });
    });
    window.addEventListener("resize", () => {
      this.setState({
        menuHeight: document.getElementById("root").offsetHeight,
      });
    });
  }

  _updateStateVal(_tt, _val) {
    let exArr = [];
    let st = this.state.user;
    if (st) {
      if (_tt == "exclude") {
        if (_val == null) {
          st[_tt] = "";
          this.setState({ excludeDinnerPrice: 0 });
        } else {
          let excludeItem = st[_tt] ? st[_tt] : "";
          exArr = excludeItem ? excludeItem.split(",") : [];
          if (!excludeItem.includes(_val)) {
            exArr.push(_val);
          } else {
            let i = 0;
            exArr.map((item, index) => {
              if (item == _val) i = index;
            });
            exArr.splice(i, 1);
          }
          if (exArr.length == 0) this.setState({ excludeDinnerPrice: 0 });
          st[_tt] = exArr.join(",");
        }
      }

      this.setState({ user: st });
      updateUser(this.state.user.api_token, st)
        .then((res) => {
          this.setState({ loader: false });
          if (res.success) {
            if (res.response) {
              saveUser(this.state.user);
              this._load();
            }
          }
        })
        .catch((res) => {
          console.log(res);
        });
    }
  }

  _alert(_title = null, _text) {
    this.setState({
      showAlert: true,
      alertTitle: _title,
      alertText: _text,
    });
  }

  _hasChange(_item) {
    let ff = false;
    if (_item.dinner1_may_change == 1) ff = true;
    if (_item.dinner2_may_change == 1) ff = true;
    if (_item.dinner3_may_change == 1) ff = true;
    if (_item.dinner4_may_change == 1) ff = true;
    if (_item.dinner5_may_change == 1) ff = true;
    return ff;
  }

  _getItemByDay(_id) {
    this.state.dayListArr.map((item) => {
      if (_id == item.value) {
        this.setState({ activeDay: item });
      }
    });
  }

  _checkDeliveryPrice() {
    if (this.state.user) {
      var price = 0;
      this.state.deliveryPrices.map((item) => {
        if (item.code) {
          var codes = item.code.split("-");
          if (
            parseInt(codes[0]) <= parseInt(this.state.user.code) &&
            parseInt(codes[1]) >= parseInt(this.state.user.code)
          ) {
            price = item.price;
          }
        }
      });

      return price;
    } else return 0;
  }

  _getTotalMetrics(_item) {
    let _ck = 0,
      _p = 0,
      _f = 0,
      _c = 0;

    if (this._checkChangeEshop(_item.value)) {
      _ck = _ck + this._checkChangeEshop(_item.value).calories;
      _p = _p + this._checkChangeEshop(_item.value).protein;
      _f = _f + this._checkChangeEshop(_item.value).fats;
      _c = _c + this._checkChangeEshop(_item.value).carbohydrates;
    }

    if (_item.dinner1) {
      _ck = _ck + _item.dinner1[0].calories;
      _p = _p + _item.dinner1[0].protein;
      _f = _f + _item.dinner1[0].fats;
      _c = _c + _item.dinner1[0].carbohydrates;
    }
    if (_item.dinner2) {
      _ck = _ck + _item.dinner2[0].calories;
      _p = _p + _item.dinner2[0].protein;
      _f = _f + _item.dinner2[0].fats;
      _c = _c + _item.dinner2[0].carbohydrates;
    }
    if (_item.dinner3) {
      _ck = _ck + _item.dinner3[0].calories;
      _p = _p + _item.dinner3[0].protein;
      _f = _f + _item.dinner3[0].fats;
      _c = _c + _item.dinner3[0].carbohydrates;
    }
    if (_item.dinner4) {
      _ck = _ck + _item.dinner4[0].calories;
      _p = _p + _item.dinner4[0].protein;
      _f = _f + _item.dinner4[0].fats;
      _c = _c + _item.dinner4[0].carbohydrates;
    }
    if (_item.dinner5) {
      _ck = _ck + _item.dinner5[0].calories;
      _p = _p + _item.dinner5[0].protein;
      _f = _f + _item.dinner5[0].fats;
      _c = _c + _item.dinner5[0].carbohydrates;
    }

    return {
      ck: _ck,
      p: _p,
      f: _f,
      c: _c,
    };
  }

  initialize() {
    var input = document.getElementById("searchTextField");
    var options = {
      types: ["geocode"],
    };
    var autocomplete = new google.maps.places.Autocomplete(input, options);
    autocomplete.setFields(["address_component"]);
    autocomplete.addListener("place_changed", () => {
      var componentForm = {
        street_number: "short_name",
        route: "long_name",
        locality: "long_name",
        administrative_area_level_1: "short_name",
        country: "long_name",
        postal_code: "short_name",
      };

      var place = autocomplete.getPlace();
      if (place) {
        for (var i = 0; i < place.address_components.length; i++) {
          var addressType = place.address_components[i].types[0];
          if (componentForm[addressType]) {
            var val = place.address_components[i][componentForm[addressType]];
            componentForm[addressType] = val;
            if (componentForm.route != "long_name") {
              if (componentForm.street_number == "short_name")
                componentForm.street_number = "";
              document.getElementById("searchTextField").value =
                componentForm.route +
                (componentForm.street_number != "" ? ", " : "") +
                componentForm.street_number;
              this.setState({
                street:
                  componentForm.route + ", " + componentForm.street_number,
              });
            }
            if (componentForm.postal_code != "short_name") {
              document.getElementById("codeTextField").value =
                componentForm.postal_code;
              this.setState({ code: componentForm.postal_code });
            }
            if (componentForm.locality != "long_name") {
              document.getElementById("cityTextField").value =
                componentForm.locality;
              this.setState({ city: componentForm.locality });
            }
            if (
              componentForm.locality == "long_name" &&
              componentForm.administrative_area_level_1 != "short_name"
            ) {
              document.getElementById("cityTextField").value =
                componentForm.administrative_area_level_1;
              this.setState({
                city: componentForm.administrative_area_level_1,
              });
            }
          }
        }
      }

      console.log(place, componentForm);
    });
  }

  _getPrice() {
    let _price = 0;
    if (this.state.cart) {
      if (this.state.cart.plan.sales) {
        _price =
          getPriceFromArraySalesEs(
            this.state.dayListArr,
            this.state.changeEshopList,
            this.state.cart.days,
            this.state.cart.size,
            this.state.cart.count,
            this.state.cart.plan.sales[0].value
          ) +
          this._checkDeliveryPrice() * this.state.cart.days +
          this.state.excludeDinnerPrice * this.state.cart.days;
      } else {
        _price =
          getPriceFromArrayEs(
            this.state.dayListArr,
            this.state.changeEshopList,
            this.state.cart.days,
            this.state.cart.size,
            this.state.cart.count
          ) +
          this._checkDeliveryPrice() * this.state.cart.days +
          this.state.excludeDinnerPrice * this.state.cart.days;
      }
    }
    return _price;
  }

  _getAdditional(_type) {
    this.setState({ loader: true, changeItemBeforeId: _type });
    getAdditionalDinner(_type).then((res) => {
      this.setState({
        loader: false,
        showMenu: true,
        menuDinners: res.response,
      });
      console.log(res);
    });
  }

  _addChange(_day, _from, _to, _to_item) {
    let _arr = this.state.changeUserList;
    let f = false,
      i = 0;
    _arr.map((item, index) => {
      if (item.day == _day && item.bid == _from) {
        i = index;
        f = true;
      }
    });
    if (f) {
      _arr.splice(i, 1);
    }
    _arr.push({
      day: _day,
      bid: _from,
      aid: _to,
      item: _to_item,
    });
    this.setState({ changeUserList: _arr });
    storeData("changeUserList", _arr);
  }

  _checkChange(_day, _from) {
    let _arr = this.state.changeUserList;
    let _item = null;
    _arr.map((item, index) => {
      if (item.day == _day && item.bid == _from) _item = item.item;
    });
    return _item;
  }

  _addChangeDelivery(_day, _city, _street, _code, _daypart, _time, _comment) {
    // console.log("HH", _day, _city, _street, _daypart, _time, _comment )
    if (_street != "") {
      console.log(_day, _city, _street, _daypart, _time, _comment);
      let _arr = this.state.changeDeliveryList;
      let f = false,
        i = 0;
      _arr.map((item, index) => {
        if (item.day == _day) {
          i = index;
          f = true;
        }
      });
      if (f) {
        _arr.splice(i, 1);
      }
      _arr.push({
        day: _day,
        city: _city,
        street: _street,
        ftdaypart: _daypart,
        code: _code,
        fttime: _time,
        comment: _comment,
      });
      console.log("_addChangeDelivery", _arr);
      this.setState({ changeDeliveryList: _arr });
      storeData("changeDeliveryList", _arr);
    }
  }

  _checkChangeDelivery(_day) {
    let _arr = this.state.changeDeliveryList;
    let _item = null;
    _arr.map((item, index) => {
      if (item.day == formatDateYMD(_day)) _item = item;
    });
    return _item;
  }

  _addChangeEshop(_day, _id, _item) {
    let _arr = this.state.changeEshopList;
    let f = false,
      i = 0;
    _arr.map((item, index) => {
      if (item.day == _day) {
        i = index;
        f = true;
      }
    });
    if (f) {
      _arr.splice(i, 1);
    }
    _arr.push({
      day: _day,
      id: _id,
      item: _item,
    });
    this.setState({ changeEshopList: _arr });
    storeData("changeEshopList", _arr);
  }

  _checkChangeEshop(_day) {
    let _arr = this.state.changeEshopList;
    let _item = null;
    _arr.map((item, index) => {
      if (item.day == _day) _item = item.item;
    });
    return _item;
  }

  _pay(_type, _bonus, _promocode, _offset_price) {
 
    let _data = {
      user_id: this.state.user.id,
      calories_id: this.state.cart.itemId,
      days: this.state.cart.days,
      programSize: this.state.cart.programSize,
      daysOffset: this._excludeDatesCount(
        this.state.currentWeeksList,
        excludeDateStart,
        excludeDateEnd
      ),
      count: this.state.cart.count,
      size: this.state.cart.size,
      payType: _type,
      bonus: _bonus,
      promocode: _promocode,
      saleId: this.state.cart.plan.sales ? this.state.cart.plan.sales[0].id : 0,
      start_date: this.state.selectedStartDate,
      end_date: formatDateYMD(
        this.state.weeksList[this.state.weeksList.length - 1].date
      ),
      changesDishes: this.state.changeUserList,
      eshop: this.state.changeEshopList,
      delivery: this.state.changeDeliveryList,
      price: _offset_price,
    };
  
    this.setState({ loader: true });
    payOrder(this.state.user.api_token, _data).then((res) => {
      console.log(res, _data);
      if (res.success) {
        if (res.response) {
          if (this.state.promoSale > 0) {
            addPromocodeToStorage(_promocode);
          }

          if (_type == 0) {
            if (res.response.return_url != "") {
              window.location.href = res.response.url;
              //console.log( res.response.url,  res.response.get );
            } else {
              this._alert(res.response.message);
            }
          } else {
            this.props.history.push("/plans");
            this._clearCart();
          }
        } else {
          this._alert(global.locale.cart_alert_error);
        }
      } else {
        this._alert(global.locale.cart_alert_error);
      }
      this.setState({ loader: false });
    });
   
  }

  _clearCart() {
    storeData("ikCart", null);
    storeData("changeEshopList", null);
    storeData("changeDeliveryList", null);
    storeData("changeUserList", null);
    this.setState({ cart: null });
  }

  _changeDaysInCart(_dd, _ii) {
    var _cart = retrieveData("ikCart");
    _cart.days = _dd;
    storeData("ikCart", _cart);

    
    this.setState({ 
      daysIndex: _ii, 
      cart: _cart ,

      promocodeText: "",
      promoSale: 0,
    }, () => {
      this._load();
    });
  }

  _changeProgramSize(_dd) {
    var _cart = retrieveData("ikCart");

    if (_dd == 0) {
      _cart.days = this.state.optionsListDays[0];
    } else {
      _cart.days = this.state.optionsListDaysSmall[0];
    }
    _cart.programSize = _dd;

    storeData("ikCart", _cart);
    this.setState({ daysIndex: 0, cart: _cart }, () => {
      this._load();
    });
  }

  _changeProgramValue(_title, _value) {
    var _cart = retrieveData("ikCart");
    console.log(_cart);
    _cart[_title] = _value;
    storeData("ikCart", _cart);
    this.setState({ cart: _cart }, () => {
      this._load();
    });
  }

  _excludeDatesCount(_arr, _start, _end) {
    let arr = [];
    let index = 0;

    let arrMap = JSON.parse(JSON.stringify(_arr));
    arrMap.map((item) => {
      if (
        !(
          new Date(item.date) >= new Date(_start) &&
          new Date(item.date) <= new Date(_end)
        )
      ) {
      } else {
        index++;
      }
    });
    return index;
  }

  _excludeIfDateMore(_arr, _date){
    let arr = [];
    let   dd2 = new Date(_date);
    _arr.map((item) => { 
      if( new Date(item.date)  < dd2 ) arr.push(item);
    })
    return arr;
  }
  //exclude dates for program Pn-Pt or Pn-Sb
  _excludeDayOff(_arr, _type, _full) {
    let arr = [];
    let replaceArr = [];
    let offsetStartDate = null;
    let index = 0;
    let arrMap = JSON.parse(JSON.stringify(_arr));
    if (_type == 1) {
      //find date
      arrMap.map((item, index) => {
        if (
          new Date(item.date).getDay() != 0 &&
          new Date(item.date).getDay() != 6
        ) {
          if (arrMap[index - 1]) offsetStartDate = item.date;
          arr.push(item);
        }
      });
      var dd = new Date(offsetStartDate);
      dd.setDate(dd.getDate() + 1);
      offsetStartDate = dd;
      arrMap.map((item, itemIndex) => {
        if (
          new Date(item.date).getDay() != 0 &&
          new Date(item.date).getDay() != 6
        ) {
        } else {
          var d = new Date(offsetStartDate);
          d.setDate(d.getDate() + index);
          index++;

          while (d.getDay() == 0 || d.getDay() == 6) {
            d.setDate(d.getDate() + 1);
            index++;
          }

          item.day = formatDate2(d);
          item.dayName = getWeekTitleByNum(getDay(d));
          item.weekDay = getWeekTitleByNum(getDay(d));
          item.date = d;

          _full.map((fitem) => {
            if (fitem.date === formatDateYMD(d)) {
              //console.log("22", item, fitem)

              item.dinner1 = fitem.dinner1;
              item.dinner2 = fitem.dinner2;
              item.dinner3 = fitem.dinner3;
              item.dinner4 = fitem.dinner4;
              item.dinner5 = fitem.dinner5;
            }
          });

          replaceArr.push(item);
        }
      });
      arr = arr.concat(replaceArr);
      let unique = [];
      arr.map((x) =>
        unique.filter((a) => a.day == x.day).length > 0 ? null : unique.push(x)
      );
      unique.sort(function (a, b) {
        return new Date(a.date) - new Date(b.date);
      });
      return unique;
    } else {
      return _arr;
    }
  }

  _excludeDates(_arr, _start, _end) {
    let arr = [];
    let replaceArr = [];
    let offsetStartDate = null;
    let index = 0;
    let arrMap = JSON.parse(JSON.stringify(_arr));

    //find date
    arrMap.map((item, index) => {
      if (
        !(
          new Date(item.date) >= new Date(_start) &&
          new Date(item.date) <= new Date(_end)
        )
      ) {
        if (arrMap[index - 1]) offsetStartDate = item.date;
        arr.push(item);
      } else {
      }
    });
    //console.log("arrMap",offsetStartDate, arr,   arrMap);

    if (new Date(offsetStartDate) <= new Date(excludeDateP1Start)) {
      offsetStartDate = excludeDateEnd;
    } else {
      var dd = new Date(offsetStartDate);
      dd.setDate(dd.getDate() + 1);
      offsetStartDate = dd;
    }
    console.log("offsetStartDate", offsetStartDate);

    arrMap.map((item, itemIndex) => {
      if (
        !(
          new Date(item.date) >= new Date(_start) &&
          new Date(item.date) <= new Date(_end)
        )
      ) {
        //arr.push(item);
        //console.log(item.date);
      } else {
        var d = new Date(offsetStartDate);
        d.setDate(d.getDate() + index);
        index++;
        if (d.getDay() == 0) {
          d.setDate(d.getDate() + 1);
          index++;
        }
        item.day = formatDate2(d);
        item.dayName = getWeekTitleByNum(getDay(d));
        item.date = d;
        replaceArr.push(item);
      }
    });
    arr = arr.concat(replaceArr);

    let unique = [];
    arr.map((x) =>
      unique.filter((a) => a.day == x.day).length > 0 ? null : unique.push(x)
    );
    unique.sort(function (a, b) {
      return new Date(a.date) - new Date(b.date);
    });

    return unique;
  }

  _excludeDeliveryDates(_arr, _wArr, _start, _end, _type = 0) {
    let arr = [];
    let replaceArr = [];
    let index = 1;
    let offsetStartDate = null;
    let arrMap = JSON.parse(JSON.stringify(_arr));
    arrMap.map((item, index) => {
      if (
        !(
          new Date(item.date) >= new Date(_start) &&
          new Date(item.date) <= new Date(_end)
        )
      ) {
        if (arrMap[index - 1]) offsetStartDate = item.date;
        arr.push(item);
      } else {
      }
    });

    if (new Date(offsetStartDate) <= new Date(excludeDateStart)) {
      offsetStartDate = offsetStartDateConst;
    } else {
      var dd = new Date(offsetStartDate);
      dd.setDate(dd.getDate() + 1);
      offsetStartDate = dd;
    }
    // console.log("arrMap",offsetStartDate, arr,   arrMap);
    arrMap.map((item, itemIndex) => {
      if (
        !(
          new Date(item.date) >= new Date(_start) &&
          new Date(item.date) <= new Date(_end)
        )
      ) {
      } else {
        var d = new Date(offsetStartDate);
        d.setDate(d.getDate() + index);

        index++;
        if(_type == 0){
          if (d.getDay() != 0 && d.getDay() != 2 && d.getDay() != 4) {
            d.setDate(d.getDate() + 1);
            index++;
          }
          if (d.getDay() != 0 && d.getDay() != 2 && d.getDay() != 4) {
            d.setDate(d.getDate() + 1);
            index++;
          }
          if (d.getDay() != 0 && d.getDay() != 2 && d.getDay() != 4) {
            d.setDate(d.getDate() + 1);
            index++;
          }
        } else {
          if (d.getDay() != 0 && d.getDay() != 6) {
            d.setDate(d.getDate() + 1);
            index++;
          }
          if (d.getDay() != 0 && d.getDay() != 6) {
            d.setDate(d.getDate() + 1);
            index++;
          }
          if (d.getDay() != 0 && d.getDay() != 6) {
            d.setDate(d.getDate() + 1);
            index++;
          }
        }

        item.calDay = formatDate2(d);
        item.weekDay = getWeekTitleByNum(getDay(d));
        item.date = d;
        replaceArr.push(item);
      }
    });

    let unique = [];
    arr = arr.concat(replaceArr);
    arr.map((x) =>
      unique.filter((a) => a.calDay == x.calDay).length > 0
        ? null
        : unique.push(x)
    );

    //fix if too much days
    if (unique[unique.length - 1].calDay == _wArr[_wArr.length - 1].day) {
      unique.splice(unique.length - 1, 1);
    }

    return unique;
  }

  _load() {
    this.setState({ menuHeight: document.getElementById("root").offsetHeight });
    this.setState({ loader: true });
    let _user = loadUser();
    //убрать из календаря
    let _excludeDaysArr = excludeCalendarDaysList;
    for (let i = -30; i < 360; i++) {
      var date = new Date();
      date.setDate(date.getDate() + i);
      if (0 == getDay(date) || 2 == getDay(date) || 4 == getDay(date)) {
      } else {
        _excludeDaysArr.push(date);
      }
    }

    this.setState({ user: _user, excludeDaysArr: _excludeDaysArr });
    if (_user == null) this.props.history.push("/login");
    if (_user) {
      getOrders(_user.api_token, _user.id).then((res) => {
        if (res.success) {
        } else {
          saveUser(null);
          this.props.history.push("/login");
        }
      });
      getProfile(_user.api_token).then((res) => {
        if (res.success && res.response.length > 0) {
          this.setState({ user: res.response[0] });
        } else {
        }
      });

      getExcludesPrice().then((res) => {
        if (res.response) {
          let arr = [{ value: null, text: global.locale.reg_exclude_text2 }];
          let price = 0;
          res.response.map((item) => {
            if (_user.exclude) {
              if (_user.exclude.includes(item.name)) {
                price = price + item.value;
                this.setState({ excludeDinnerPrice: price });
              }
            }

            arr.push({
              value: item.name,
              text: item.name + " +" + item.value + "Kč/den",
            });
          });
          this.setState({ excludesList: arr });
        }
      });
    }
    getEShop().then((res) => {
      this.setState({ menuEShop: res.response });
    });

    getDeliveryPrice().then((res) => {
      console.log(res);
      this.setState({ deliveryPrices: res.response });
    });

    let _changeEshopList = retrieveData("changeEshopList");
    if (_changeEshopList) this.setState({ changeEshopList: _changeEshopList });
    let _changeDeliveryList = retrieveData("changeDeliveryList");
    if (_changeDeliveryList)
      this.setState({ changeDeliveryList: _changeDeliveryList });
    let _changeUserList = retrieveData("changeUserList");
    if (_changeUserList) this.setState({ changeUserList: _changeUserList });

    let _cart = retrieveData("ikCart");
    //console.log(_cart)
    this.setState({
      cart: _cart,
    });
    //

    let _selectedStartDeliveryDate = getDateWithOffsetAllowed2(
      2,
      getFormatedCurrentDate()
    )[0].text;

    console.log("_selectedStartDeliveryDate", _selectedStartDeliveryDate);

    if (retrieveData("ftdate")) {
      let _storedDate = retrieveData("ftdate");
      //format mm-dd-yy
      _storedDate = _storedDate.split("-");
      let y = _storedDate[2];
      let m = parseInt(_storedDate[0]) - 1;
      let d = parseInt(_storedDate[1]);

      _storedDate = new Date(y, m, d);

      let _formatSelectedStartDeliveryDate =
        _selectedStartDeliveryDate.split("-");
      let y2 = _formatSelectedStartDeliveryDate[2];
      let m2 = parseInt(_formatSelectedStartDeliveryDate[0]) - 1;
      let d2 = parseInt(_formatSelectedStartDeliveryDate[1]);

      if (_storedDate.getTime() > new Date(y2, m2, d2).getTime()) {
        _selectedStartDeliveryDate = retrieveData("ftdate");
        this.setState({
          selectedFirstDate: retrieveData("ftdate"),
          selectedStartDeliveryDate: retrieveData("ftdate"),
        });
      }
    }

    if (_cart) {
      getDaysByCalories(_cart.itemId, getFormatedCurrentDate()).then((res) => {
        this.setState({ loader: false });
        if (res.success) {
          var _dates = [];
          var _dates2 = [];
          var _daysArr = [];
          var _index = 0;

          var d = getDateWithOffsetAllowed2(
            this.state.dayOffset,
            convertStringToDateForSending(_selectedStartDeliveryDate)
          )[0].value;

          //если день первой доставки попадате в праздник то пернесем его
          if( new Date(d) < new Date(firstDDinNY) && new Date(d) > new Date(excludeDateP1Start)  ) {
           
            d = getDateWithOffsetAllowed2(
              this.state.dayOffset,
               (firstDDinNY)
            )[0].value;
            console.warn("%c delivery in holiday " + d , 'background: #222; color: #bada55')
          }

          var _minDate = new Date(d);
          _minDate.setDate(_minDate.getDate() + 1);
          _minDate.setHours(0, 0, 0, 0);

          if (_cart.programSize == 0) {
            if (
              _cart.days == 4 ||
              _cart.days == 6 ||
              _cart.days == 12 ||
              _cart.days == 24
            ) {
            } else {
              _cart.days = 4;
            }
          } else {
            if (
              _cart.days == 5 ||
              _cart.days == 10 ||
              _cart.days == 15 ||
              _cart.days == 20
            ) {
            } else {
              _cart.days = 5;
            }
          }

          res.response.map((item, index) => {
            let _itemDate = new Date(item.date);
            _itemDate.setHours(0, 0, 0, 0);

            if (_cart.days > _index && _minDate <= _itemDate) {
              _index++;
              _daysArr.push({
                id: item.id,
                day: formatDate2(item.date),
                dayName: getWeekTitleByNum(getDay(item.date)),
                date: item.date,
                active: false,
                editMenu: this._hasChange(item),
                disabled: false,
              });
              _dates.push({
                value: item.id,
                day: formatDate2(item.date),
                dayName: getWeekTitleByNum(getDay(item.date)),
                date: item.date,
                weekDay: getWeekTitleByNum(getDay(item.date)),
                calDay: formatDate2(item.date),
                dinner1_may_change: item.dinner1_may_change,
                dinner2_may_change: item.dinner2_may_change,
                dinner3_may_change: item.dinner3_may_change,
                dinner4_may_change: item.dinner4_may_change,
                dinner5_may_change: item.dinner5_may_change,
                dinner1: item.dinner1,
                dinner2: item.dinner2,
                dinner3: item.dinner3,
                dinner4: item.dinner4,
                dinner5: item.dinner5,
              });
            }
          });
         

          //_cart.days = _cart.days;
          //exclude if programs size Pn-Pt
          let _weekList = this._excludeDates(
            _daysArr,
            excludeDateStart,
            excludeDateP1End
          );
          let _weekListEx = this._excludeDayOff(
            _weekList,
            this.state.cart.programSize,
            res.response
          );
          //Нужно перенести даты повторно т.к. мы исключили выходные
          let _weekListEx2 = this._excludeDates(
            _weekListEx,
            excludeDateStart,
            excludeDateP1End
          ); 

          //формируем список дней на доставку
          let start_date = _weekListEx2[0].date;
          let end_date = _weekListEx2[_weekListEx2.length - 1].date;
          let deliveryDatesArray = generateDatesIntervalArray(
            new Date(start_date),
            new Date(end_date)
          );
          if (deliveryDatesArray[0])
            deliveryDatesArray.unshift(
              getLastDeliveredDay(deliveryDatesArray[0])
            );
          var _index2 = 0;

          let startId = res.response[0].id;
          res.response.map((sItem) => {
            if (sItem.date === start_date) startId = sItem.id;
          });

          deliveryDatesArray.map((deliveryDatesArrayItem, index) => { 
              if (
                _cart.days > _index2 && (deliveryDatesArrayItem.getDay() == 0 ||
                deliveryDatesArrayItem.getDay() == 2 ||
                deliveryDatesArrayItem.getDay() == 4 )
              ) { 
                _dates2.push({
                  value: startId,
                  date: deliveryDatesArrayItem,
                  weekDay: getWeekTitleByNum(getDay(deliveryDatesArrayItem)),
                  calDay: formatDate2(deliveryDatesArrayItem),
                });
                startId++;
                _index2++;
              }
          });



          _dates = this._excludeDayOff(
            _dates,
            this.state.cart.programSize,
            res.response
          );

          let _dayListArrDeliveryEx = this._excludeDeliveryDates(
            _dates2,
            _weekListEx2,
            excludeDateStart,
            excludeDateP1End,
            this.state.cart.programSize
          ); 
      
           //если есть даты которые выходят за последний день программы убираем
           _dayListArrDeliveryEx = this._excludeIfDateMore(_dayListArrDeliveryEx, _weekListEx2[_weekListEx2.length-1].date);

         // console.log("_dayListArrDeliveryEx", this.state.cart.programSize, _dates, _dates2,  _dayListArrDeliveryEx)
          // console.log("weeksList",   _dates2); 
     
        let _selectedStartDate = _dates.filter((item) => {return new Date(item.date) > excludeCalendarDaysList2[excludeCalendarDaysList2.length - 1]})[0].date;
     
         if( new Date(this.state.selectedFirstDate) < new Date(_selectedStartDate)){
          let _selectedFirstDate = _dates2.filter((item) => {return new Date(item.date) > excludeCalendarDaysList2[excludeCalendarDaysList2.length - 1]})[0].date;

          this.setState({ selectedFirstDate:  formatDate(_selectedFirstDate)  })
         }
 
          this.setState({
            cart: _cart,
            currentWeeksList: _daysArr,
            weeksList: _weekListEx2,
            dayListArr: _dates,
            selectedStartDate: _selectedStartDate,
            dayListArrDelivery: _dayListArrDeliveryEx,
            activeDay: _dates.length > 0 ? _dates[0] : 0,
          });
        }
      });
    } else {
      this.setState({ loader: false });
    }

    if (this.props.match.params.id) {
      if (this.props.location.search) {
        let codesList = this.props.location.search.split("&");
        codesList.map((item) => {
          if (item.includes("PRCODE")) {
            let code = item.split("=");
            if (code[1]) {
              if (parseInt(code[1]) == 0) {
                confirmOrder(
                  this.props.match.params.id,
                  this.props.match.params.token
                ).then((res) => {
                  if (res.success) {
                    this.props.history.push("/plans");
                    storeData("ikCart", null);
                  }
                });
              } else {
                alert(
                  "Technical problem. Please try a different payment method or try again later."
                );
                storeData("ikCart", null);
              }
            }
          }
        });
      }

      /*
   
      */
    }
  }

  render() {
    var deliveryItemsList = this.state.dayListArrDelivery.map((item, index) => {
      if (
        ( 
          0 == getDay(item.date) ||
          2 == getDay(item.date) ||
          4 == getDay(item.date)
        ) &&
        new Date(item.date) >=
          getDateWithOffsetAllowed2(2, getFormatedCurrentDate())[0].value
      ) {
        return (
          <UiDeliveryItem
            key={index}
            date={item.calDay}
            day={item.calDay + ", " + item.weekDay}
            dayTime={
              !this._checkChangeDelivery(item.date)
                ? this.state.user.ftdaypart
                : this._checkChangeDelivery(item.date).ftdaypart
            }
            timeRange={
              !this._checkChangeDelivery(item.date)
                ? this.state.user.fttime
                : this._checkChangeDelivery(item.date).fttime
            }
            adress={
              (!this._checkChangeDelivery(item.date)
                ? this.state.user.city
                : this._checkChangeDelivery(item.date).city) +
              ", " +
              (!this._checkChangeDelivery(item.date)
                ? this.state.user.street
                : this._checkChangeDelivery(item.date).street)
            }
            comment={
              !this._checkChangeDelivery(item.date)
                ? this.state.user.comment
                : this._checkChangeDelivery(item.date).comment
            }
            callBackInit={() => {
              this.setState(
                {
                  showChangeAdress: true,
                  selectedChangeAdressDay: formatDateYMD(item.date),
                },
                () => {
                  this.initialize();
                }
              );
            }}
          />
        );
      }
    });

    return (
      <div ref={this.divPage} id="page">
        <UiLoader show={this.state.loader} />

        <UiAlert
          show={this.state.showAlert}
          title={this.state.alertTitle}
          text={this.state.alertText}
          callBack={() => this.setState({ showAlert: false })}
          leftBtnText="Ок"
        />

        <UiModalMenu
          show={this.state.showMenu}
          menu={this.state.menuDinners}
          callBackClose={() => this.setState({ showMenu: false })}
          callBackChange={(_to, _to_item) =>
            this._addChange(
              this.state.activeDay.value,
              this.state.changeItemBeforeId,
              _to,
              _to_item
            )
          }
        />
        <UiModalMenu
          show={this.state.showEMenu}
          menu={this.state.menuEShop}
          callBackClose={() => this.setState({ showEMenu: false })}
          callBackChange={(_to, _to_item) =>
            this._addChangeEshop(this.state.activeDay.value, _to, _to_item)
          }
        />
        <UiModalPay
          show={this.state.cart ? this.state.showPayModal : null}
          title={this.state.cart ? this.state.cart.plan.programName : null}
          description={
            this.state.cart ? this.state.cart.plan.programAbout : null
          }
          days={this.state.cart ? this.state.cart.days : null}
          price={this._getPrice()}
          bonus={this.state.user.bonus}
          callBackClose={() => this.setState({ showPayModal: false })}
          callBack={(type, bonus, promocode, price) => {
            this._pay(type, bonus, this.state.promocodeText, price);
          }}
          sale={this.state.promoSale}
          onPromo={(promocode) => {
            checkPromo(promocode).then((val) => {
              if (val.response) {
                if (val.response.sale)
                  if (val.response.is_long_programm == 0) {
                    if (!checkDublePromo(promocode)) {
                      this.setState({
                        promocodeText: promocode,
                        promoSale: val.response.sale,
                      });
                    } else {
                      this.setState({ promocodeText: null, promoSale: 0 });
                      this._alert(global.locale.modal_pay_promocode_not_valid);
                    }
                  } else {
                    if (
                      this.state.cart.days == 20 ||
                      this.state.cart.days == 24
                    ) {
                      this.setState({
                        promocodeText: promocode,
                        promoSale: val.response.sale,
                      });
                    } else {
                      this.setState({ promocodeText: null, promoSale: 0 });
                      this._alert(global.locale.modal_pay_promocode_not_valid);
                    }
                  }
              } else {
                this.setState({ promocodeText: null, promoSale: 0 });
                this._alert(global.locale.modal_pay_promocode_not_valid);
              }
            });
          }}
        />

        <UiModalChangeAdress
          show={this.state.showChangeAdress}
          selectedFTTime={this.state.selectedFTTime}
          firstTimeArr={this.state.firstTimeArr}
          closeCallBack={() => this.setState({ showChangeAdress: false })}
          callBack={(dp, time, comment, city, street, code) => {
            this.setState({ showChangeAdress: false });
            if (city.length > 0 && street.length > 0) {
              this._addChangeDelivery(
                this.state.selectedChangeAdressDay,
                city,
                street,
                code,
                dp,
                time,
                comment
              );
            } else {
              this._alert(global.locale.plans_alert_save_err);
            }
          }}
        />

        <div
          className={
            "wrapper login-wrapper" + (this.state.showMenuBar ? " scroll" : "")
          }
        >
          <div className="content">
            <UiHeader
              profile
              menuHeight={this.state.menuHeight}
              langCallBack={(val) => this.changeLang(val)}
            />
            <section className="profile-title image-bg small-section">
              <div className="image-shadow">
                <div className="container">
                  <h2 className="section-title">{global.locale.cart_basket}</h2>
                </div>
              </div>
            </section>

            <section className="profile-wrap">
              <div className="container">
                <div className="row">
                  <div className="col-lg-4 col-xl-3">
                    <UiMenuProfile
                      activeItem={1}
                      callBack={(val) => {
                        if (val == 0) this.props.history.push("/profile");
                        if (val == 1) this.props.history.push("/cart");
                        if (val == 2) this.props.history.push("/plans");
                        if (val == 3) this.props.history.push("/subscribe");
                        if (val == 4) this.props.history.push("/history");
                        if (val == 5) this.props.history.push("/bonus");
                      }}
                    />
                  </div>

                  <div className="col-md-12 col-lg-8 col-xl-9">
                    {this.state.cart ? (
                      <div className="row">
                        <div className="col-md-12">
                          <div className="profile-order">
                            <h3 className="form-title">
                              {global.locale.cart_new_order}
                            </h3>

                            <div className="row">
                              <div className="col-md-12">
                                <div className="plan-full-card basket-plan-full-card">
                                  <div className="card-info-wrap">
                                    <div className="plan-card-info">
                                      <div className="plan-card-img">
                                        <img
                                          src={require("../../assets/imgs/menu-type/healthy.svg")}
                                        />
                                      </div>
                                      <div className="card-info">
                                        <span className="card-info-status">
                                          {global.locale.cart_dont_paid}
                                        </span>
                                        <h3 className="card-info-title">
                                          {this.state.cart.plan.programName}
                                        </h3>
                                      </div>
                                    </div>
                                    <p className="about-program">
                                      <strong>
                                        {this.state.lang == "ch"
                                          ? this.state.cart.plan.programAbout_ch
                                          : this.state.lang == "ru"
                                          ? this.state.cart.plan.programAbout_ru
                                          : this.state.lang == "en"
                                          ? this.state.cart.plan.programAbout_eb
                                          : ""}
                                      </strong>
                                    </p>
                                    <p className="about-program">
                                      {this.state.lang == "ch"
                                        ? this.state.cart.plan
                                            .programDescription_ch
                                        : this.state.lang == "ru"
                                        ? this.state.cart.plan
                                            .programDescription_ru
                                        : this.state.lang == "en"
                                        ? this.state.cart.plan
                                            .programDescription_en
                                        : ""}
                                      {}
                                    </p>
                                  </div>
                                  <div className="card-price">
                                    <UiBtnPinkOutline
                                      btnText={
                                        global.locale.cart_btn_cancel_order
                                      }
                                      onClick={() => this._clearCart()}
                                      black
                                      btnsm
                                    />
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="plan-full-card basket-plan-full-card">
                                  <div className="card-info-wrap">
                                    <div className="plan-card-info">
                                      <div className="plan-card-img">
                                        <img src={deliveryGreenIcon} />
                                      </div>
                                      <div className="card-info">
                                        <h3 className="card-info-title">
                                          {global.locale.cart_delivery_data}
                                        </h3>

                                        <p className="comment">
                                          {global.locale.cart_delivery_info_1}
                                          <br />
                                          {global.locale.cart_delivery_info_2}
                                          <br />
                                          {global.locale.cart_delivery_info_3}
                                          <br />
                                          {global.locale.cart_delivery_info_4}
                                          <br />
                                        </p>
                                        <div className="row align-items-center">
                                          <div className="col-md-4">
                                            <UiSelectDate
                                              excludeDates={
                                                this.state.excludeDaysArr
                                              }
                                              onChange={(data) => {
                                                console.log(data)
                                                this.setState({
                                                  selectedFirstDate:
                                                    formatDate(data),
                                                  selectedStartDeliveryDate:
                                                    formatDate(data),
                                                  dayOffset: 0,
                                                });

                                                storeData(
                                                  "ftdate",
                                                  formatDate(data)
                                                );
                                                this._load();
                                              }}
                                              minDate={
                                                new Date(this.state.minDate)
                                              }
                                              selected={
                                                new Date(
                                                  this.state.selectedFirstDate.replace(
                                                    /-/g,
                                                    "/"
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                          <div className="col-md-4">
                                            <UiSelectScroll
                                              selectedText={
                                                this.state.selectedFTTime
                                              }
                                              selectItems={
                                                this.state.firstTimeArr
                                              }
                                              callBack={(val) =>
                                                this.setState({
                                                  selectedFTTime: val.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>

                              <div className="col-md-12">
                                <div className="plan-full-card basket-plan-full-card">
                                  <div className="card-info-wrap">
                                    <div className="plan-card-info basket-plan-selects">
                                      {!this.state.loader ? (
                                        <div className="card-info">
                                          {this.state.cart.programSize == 0 ? (
                                            <div className="card-info-length">
                                              <label>
                                                {
                                                  global.locale
                                                    .home_food_menu_option_1
                                                }
                                              </label>
                                              <UiSelect
                                                currentIndex={
                                                  this.state.cart.days == 4
                                                    ? 0
                                                    : this.state.cart.days == 6
                                                    ? 1
                                                    : this.state.cart.days == 12
                                                    ? 2
                                                    : this.state.cart.days == 24
                                                    ? 3
                                                    : 0
                                                }
                                                optionList={[
                                                  global.locale
                                                    .home_food_menu_option_1_item_1,
                                                  global.locale
                                                    .home_food_menu_option_1_item_2,
                                                  global.locale
                                                    .home_food_menu_option_1_item_3,
                                                  global.locale
                                                    .home_food_menu_option_1_item_4,
                                                ]}
                                                callBack={(val) =>
                                                  this._changeDaysInCart(
                                                    this.state.optionsListDays[
                                                      val
                                                    ],
                                                    val
                                                  )
                                                }
                                              />
                                            </div>
                                          ) : (
                                            <div className="card-info-length">
                                              <label>
                                                {
                                                  global.locale
                                                    .home_food_menu_option_1
                                                }
                                              </label>
                                              <UiSelect
                                                currentIndex={
                                                  this.state.cart.days == 5
                                                    ? 0
                                                    : this.state.cart.days == 10
                                                    ? 1
                                                    : this.state.cart.days == 15
                                                    ? 2
                                                    : this.state.cart.days == 20
                                                    ? 3
                                                    : 0
                                                }
                                                optionList={[
                                                  global.locale
                                                    .home_food_menu_option_1_item_5,
                                                  global.locale
                                                    .home_food_menu_option_1_item_6,
                                                  global.locale
                                                    .home_food_menu_option_1_item_7,
                                                  global.locale
                                                    .home_food_menu_option_1_item_8,
                                                ]}
                                                callBack={(val) =>
                                                  this._changeDaysInCart(
                                                    this.state
                                                      .optionsListDaysSmall[
                                                      val
                                                    ],
                                                    val
                                                  )
                                                }
                                              />
                                            </div>
                                          )}
                                          <div className="card-info-length">
                                            <label>
                                              {
                                                global.locale
                                                  .home_food_menu_option_4
                                              }
                                            </label>

                                            <UiSelect
                                              currentIndex={
                                                this.state.cart.programSize
                                              }
                                              optionList={[
                                                global.locale
                                                  .home_food_menu_option_4_item_1,
                                                global.locale
                                                  .home_food_menu_option_4_item_2,
                                              ]}
                                              callBack={(val) => {
                                                this._changeProgramSize(val);
                                              }}
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>

                                    <div className="plan-card-info basket-plan-selects">
                                      <div className="card-info">
                                        <div className="card-info-length">
                                          <label>
                                            {
                                              global.locale
                                                .home_food_menu_option_2
                                            }
                                          </label>

                                          <UiSelect
                                            currentIndex={this.state.cart.size}
                                            optionList={[
                                              global.locale
                                                .home_food_menu_option_2_item_1,
                                              global.locale
                                                .home_food_menu_option_2_item_2,
                                              global.locale
                                                .home_food_menu_option_2_item_3,
                                              global.locale
                                                .home_food_menu_option_2_item_5,
                                              global.locale
                                                .home_food_menu_option_2_item_6,
                                            ]}
                                            callBack={(val) =>
                                              this._changeProgramValue(
                                                "size",
                                                val
                                              )
                                            }
                                          />
                                        </div>
                                        <div className="card-info-length">
                                          <label>
                                            {
                                              global.locale
                                                .home_food_menu_option_3
                                            }
                                          </label>
                                          <UiSelect
                                            currentIndex={
                                              this.state.cart.count - 1
                                            }
                                            optionList={["1", "2", "3", "4"]}
                                            callBack={(val) =>
                                              this._changeProgramValue(
                                                "count",
                                                val + 1
                                              )
                                            }
                                          />
                                        </div>
                                      </div>
                                    </div>

                                    <p>
                                      <label>
                                        {global.locale.reg_exclude_text}
                                      </label>
                                      <UiSelectScroll
                                        selectedText={this.state.user.exclude}
                                        selectItems={this.state.excludesList}
                                        selectedList={this.state.user.exclude}
                                        callBack={(val) => {
                                          this._updateStateVal(
                                            "exclude",
                                            val.value
                                          );
                                        }}
                                      />
                                    </p>
                                  </div>
                                  <div className="card-price">
                                    <p className="card-price-text">
                                      {global.locale.cart_delivery}:{" "}
                                      <b>
                                        {this._checkDeliveryPrice() *
                                          this.state.cart.days}{" "}
                                        {global.locale.cart_currency}
                                      </b>
                                    </p>
                                    {this.state.excludeDinnerPrice > 0 ? (
                                      <p className="card-price-text">
                                        {global.locale.reg_exclude_text}:{" "}
                                        <b>
                                          {this.state.excludeDinnerPrice *
                                            this.state.cart.days}{" "}
                                          {global.locale.cart_currency}
                                        </b>
                                      </p>
                                    ) : null}

                                    <p className="card-price-text">
                                      {global.locale.cart_to_paid}:{" "}
                                      <strong>
                                        {this._getPrice()}{" "}
                                        {global.locale.cart_currency}
                                      </strong>
                                    </p>

                                    {this.state.cart.plan.sales ? (
                                      <UiBtnGreen
                                        btnText={global.locale.cart_btn_paid}
                                        onClick={() =>
                                          this.setState({ showPayModal: true })
                                        }
                                        black
                                        btnsm
                                        mb2
                                      />
                                    ) : (
                                      <UiBtnGreen
                                        btnText={global.locale.cart_btn_paid}
                                        onClick={() =>
                                          this.setState({ showPayModal: true })
                                        }
                                        black
                                        btnsm
                                        mb2
                                      />
                                    )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="plan-cards-list">
                              <UiCalendar
                                weeksList={this.state.weeksList}
                                callBack={(val) => {
                                  console.log(val);
                                  this._getItemByDay(val.id);
                                }}
                              />
                              <div className="plan-cards-change">
                                <img src={changeIcon} />
                                <span>- {global.locale.cart_info}</span>
                              </div>
                              <div className="cards-list">
                                <div className="row">
                                  <div className="col-md-12">
                                    <div className="order-tabs">
                                      <button
                                        onClick={() =>
                                          this.setState({ activeOrderTab: 0 })
                                        }
                                        className={
                                          "order-tab" +
                                          (this.state.activeOrderTab == 0
                                            ? " active"
                                            : "")
                                        }
                                      >
                                        <img src={menuIcon} />
                                        <span>{global.locale.cart_menu}</span>
                                      </button>
                                      <button
                                        onClick={() =>
                                          this.setState({ activeOrderTab: 1 })
                                        }
                                        className={
                                          "order-tab" +
                                          (this.state.activeOrderTab == 1
                                            ? " active"
                                            : "")
                                        }
                                      >
                                        <img src={deliveryIcon} />
                                        <span>
                                          {global.locale.cart_delivery}
                                        </span>
                                      </button>
                                    </div>
                                    {this.state.activeOrderTab == 0 ? (
                                      <div>
                                        <div className="plan-day-info">
                                          <p>
                                            {global.locale.cart_summary_in}{" "}
                                            {this.state.activeDay.weekDay}:{" "}
                                            <span>
                                              {
                                                this._getTotalMetrics(
                                                  this.state.activeDay
                                                ).ck
                                              }
                                              {global.locale.cart_kkal}
                                            </span>
                                            &nbsp; |&nbsp;{" "}
                                            {global.locale.cart_B}:
                                            {
                                              this._getTotalMetrics(
                                                this.state.activeDay
                                              ).p
                                            }
                                            &nbsp;&nbsp;{global.locale.cart_J}:
                                            {
                                              this._getTotalMetrics(
                                                this.state.activeDay
                                              ).f
                                            }
                                            &nbsp;&nbsp;{global.locale.cart_U}:
                                            {
                                              this._getTotalMetrics(
                                                this.state.activeDay
                                              ).c
                                            }{" "}
                                          </p>
                                        </div>
                                        <div className="row">
                                          {this.state.activeDay.dinner1 &&
                                          (this.state.cart.size == 0 ||
                                            this.state.cart.size == 1 ||
                                            this.state.cart.size == 2 ||
                                            this.state.cart.size == 4) ? (
                                            <div className="col-xl-4 col-lg-6 d-flex plan-card-stretch">
                                              <UiPlanCard
                                                addItem={false}
                                                image={{
                                                  uri: !this._checkChange(
                                                    this.state.activeDay.value,
                                                    0
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner1[0].image
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        0
                                                      ).image,
                                                }}
                                                day={
                                                  this.state.activeDay.weekDay
                                                }
                                                title={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    0
                                                  )
                                                    ? this.locale(
                                                        this.state.activeDay
                                                          .dinner1[0],
                                                        "title"
                                                      )
                                                    : this.locale(
                                                        this._checkChange(
                                                          this.state.activeDay
                                                            .value,
                                                          0
                                                        ),
                                                        "title"
                                                      )
                                                }
                                                ingredient={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    0
                                                  )
                                                    ? this.locale(
                                                        this.state.activeDay
                                                          .dinner1[0],
                                                        "description"
                                                      )
                                                    : this.locale(
                                                        this._checkChange(
                                                          this.state.activeDay
                                                            .value,
                                                          0
                                                        ),
                                                        "description"
                                                      )
                                                }
                                                lanchType={
                                                  global.locale
                                                    .home_food_menu_text_3
                                                }
                                                about={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    0
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner1[0].calories +
                                                      " " +
                                                      global.locale.cart_kkal
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        0
                                                      ).calories +
                                                      " " +
                                                      global.locale.cart_kkal +
                                                      ", " +
                                                      (!this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        0
                                                      )
                                                        ? this.state.activeDay
                                                            .dinner1[0].weight
                                                        : this._checkChange(
                                                            this.state.activeDay
                                                              .value,
                                                            0
                                                          ).weight) +
                                                      " " +
                                                      global.locale.cart_gramm
                                                }
                                                ccal={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    0
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner1[0].calories
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        0
                                                      ).calories
                                                }
                                                protein={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    0
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner1[0].protein
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        0
                                                      ).protein
                                                }
                                                fats={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    0
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner1[0].fats
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        0
                                                      ).fats
                                                }
                                                carbo={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    0
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner1[0]
                                                        .carbohydrates
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        0
                                                      ).carbohydrates
                                                }
                                                hasChange={
                                                  this.state.activeDay
                                                    .dinner1_may_change == 1
                                                }
                                                changeCallBack={() => {
                                                  this._getAdditional(0);
                                                }}
                                              />
                                            </div>
                                          ) : null}
                                          {this.state.activeDay.dinner2 &&
                                          this.state.cart.size == 0 ? (
                                            <div className="col-xl-4 col-lg-6 d-flex plan-card-stretch">
                                              <UiPlanCard
                                                addItem={false}
                                                image={{
                                                  uri: !this._checkChange(
                                                    this.state.activeDay.value,
                                                    1
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner2[0].image
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        1
                                                      ).image,
                                                }}
                                                day={
                                                  this.state.activeDay.weekDay
                                                }
                                                title={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    1
                                                  )
                                                    ? this.locale(
                                                        this.state.activeDay
                                                          .dinner2[0],
                                                        "title"
                                                      )
                                                    : this.locale(
                                                        this._checkChange(
                                                          this.state.activeDay
                                                            .value,
                                                          1
                                                        ),
                                                        "title"
                                                      )
                                                }
                                                ingredient={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    1
                                                  )
                                                    ? this.locale(
                                                        this.state.activeDay
                                                          .dinner2[0],
                                                        "description"
                                                      )
                                                    : this.locale(
                                                        this._checkChange(
                                                          this.state.activeDay
                                                            .value,
                                                          1
                                                        ),
                                                        "description"
                                                      )
                                                }
                                                lanchType={
                                                  global.locale
                                                    .home_food_menu_text_4
                                                }
                                                about={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    1
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner2[0].calories +
                                                      " " +
                                                      global.locale.cart_kkal
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        1
                                                      ).calories +
                                                      " " +
                                                      global.locale.cart_kkal +
                                                      ", " +
                                                      (!this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        1
                                                      )
                                                        ? this.state.activeDay
                                                            .dinner2[0].weight
                                                        : this._checkChange(
                                                            this.state.activeDay
                                                              .value,
                                                            1
                                                          ).weight) +
                                                      " " +
                                                      global.locale.cart_gramm
                                                }
                                                ccal={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    1
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner2[0].calories
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        1
                                                      ).calories
                                                }
                                                protein={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    1
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner2[0].protein
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        1
                                                      ).protein
                                                }
                                                fats={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    1
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner2[0].fats
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        1
                                                      ).fats
                                                }
                                                carbo={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    1
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner2[0]
                                                        .carbohydrates
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        1
                                                      ).carbohydrates
                                                }
                                                hasChange={
                                                  this.state.activeDay
                                                    .dinner2_may_change == 1
                                                }
                                                changeCallBack={() => {
                                                  this._getAdditional(1);
                                                }}
                                              />
                                            </div>
                                          ) : null}
                                          {this.state.activeDay.dinner3 &&
                                          (this.state.cart.size == 0 ||
                                            this.state.cart.size == 1 ||
                                            this.state.cart.size == 3 ||
                                            this.state.cart.size == 4) ? (
                                            <div className="col-xl-4 col-lg-6 d-flex plan-card-stretch">
                                              <UiPlanCard
                                                addItem={false}
                                                image={{
                                                  uri: !this._checkChange(
                                                    this.state.activeDay.value,
                                                    2
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner3[0].image
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        2
                                                      ).image,
                                                }}
                                                day={
                                                  this.state.activeDay.weekDay
                                                }
                                                title={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    2
                                                  )
                                                    ? this.locale(
                                                        this.state.activeDay
                                                          .dinner3[0],
                                                        "title"
                                                      )
                                                    : this.locale(
                                                        this._checkChange(
                                                          this.state.activeDay
                                                            .value,
                                                          2
                                                        ),
                                                        "title"
                                                      )
                                                }
                                                ingredient={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    2
                                                  )
                                                    ? this.locale(
                                                        this.state.activeDay
                                                          .dinner3[0],
                                                        "description"
                                                      )
                                                    : this.locale(
                                                        this._checkChange(
                                                          this.state.activeDay
                                                            .value,
                                                          2
                                                        ),
                                                        "description"
                                                      )
                                                }
                                                lanchType={
                                                  global.locale
                                                    .home_food_menu_text_5
                                                }
                                                about={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    2
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner3[0].calories +
                                                      " " +
                                                      global.locale.cart_kkal
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        2
                                                      ).calories +
                                                      " " +
                                                      global.locale.cart_kkal +
                                                      ", " +
                                                      (!this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        2
                                                      )
                                                        ? this.state.activeDay
                                                            .dinner3[0].weight
                                                        : this._checkChange(
                                                            this.state.activeDay
                                                              .value,
                                                            2
                                                          ).weight) +
                                                      " " +
                                                      global.locale.cart_gramm
                                                }
                                                ccal={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    2
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner3[0].calories
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        2
                                                      ).calories
                                                }
                                                protein={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    2
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner3[0].protein
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        2
                                                      ).protein
                                                }
                                                fats={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    2
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner3[0].fats
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        2
                                                      ).fats
                                                }
                                                carbo={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    2
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner3[0]
                                                        .carbohydrates
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        2
                                                      ).carbohydrates
                                                }
                                                hasChange={
                                                  this.state.activeDay
                                                    .dinner3_may_change == 1
                                                }
                                                changeCallBack={() => {
                                                  this._getAdditional(2);
                                                }}
                                              />
                                            </div>
                                          ) : null}
                                          {this.state.activeDay.dinner4 &&
                                          this.state.cart.size == 0 ? (
                                            <div className="col-xl-4 col-lg-6 d-flex plan-card-stretch">
                                              <UiPlanCard
                                                addItem={false}
                                                image={{
                                                  uri: !this._checkChange(
                                                    this.state.activeDay.value,
                                                    3
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner4[0].image
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        3
                                                      ).image,
                                                }}
                                                day={
                                                  this.state.activeDay.weekDay
                                                }
                                                title={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    3
                                                  )
                                                    ? this.locale(
                                                        this.state.activeDay
                                                          .dinner4[0],
                                                        "title"
                                                      )
                                                    : this.locale(
                                                        this._checkChange(
                                                          this.state.activeDay
                                                            .value,
                                                          3
                                                        ),
                                                        "title"
                                                      )
                                                }
                                                ingredient={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    3
                                                  )
                                                    ? this.locale(
                                                        this.state.activeDay
                                                          .dinner4[0],
                                                        "description"
                                                      )
                                                    : this.locale(
                                                        this._checkChange(
                                                          this.state.activeDay
                                                            .value,
                                                          3
                                                        ),
                                                        "description"
                                                      )
                                                }
                                                lanchType={
                                                  global.locale
                                                    .home_food_menu_text_6
                                                }
                                                about={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    3
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner4[0].calories +
                                                      " " +
                                                      global.locale.cart_kkal
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        3
                                                      ).calories +
                                                      " " +
                                                      global.locale.cart_kkal +
                                                      ", " +
                                                      (!this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        3
                                                      )
                                                        ? this.state.activeDay
                                                            .dinner4[0].weight
                                                        : this._checkChange(
                                                            this.state.activeDay
                                                              .value,
                                                            3
                                                          ).weight) +
                                                      " " +
                                                      global.locale.cart_gramm
                                                }
                                                ccal={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    3
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner4[0].calories
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        3
                                                      ).calories
                                                }
                                                protein={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    3
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner4[0].protein
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        3
                                                      ).protein
                                                }
                                                fats={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    3
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner4[0].fats
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        3
                                                      ).fats
                                                }
                                                carbo={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    3
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner4[0]
                                                        .carbohydrates
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        3
                                                      ).carbohydrates
                                                }
                                                hasChange={
                                                  this.state.activeDay
                                                    .dinner4_may_change == 1
                                                }
                                                changeCallBack={() => {
                                                  this._getAdditional(3);
                                                }}
                                              />
                                            </div>
                                          ) : null}
                                          {this.state.activeDay.dinner5 &&
                                          (this.state.cart.size == 0 ||
                                            this.state.cart.size == 2 ||
                                            this.state.cart.size == 3 ||
                                            this.state.cart.size == 4) ? (
                                            <div className="col-xl-4 col-lg-6 d-flex plan-card-stretch">
                                              <UiPlanCard
                                                addItem={false}
                                                image={{
                                                  uri: !this._checkChange(
                                                    this.state.activeDay.value,
                                                    4
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner5[0].image
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        4
                                                      ).image,
                                                }}
                                                day={
                                                  this.state.activeDay.weekDay
                                                }
                                                title={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    4
                                                  )
                                                    ? this.locale(
                                                        this.state.activeDay
                                                          .dinner5[0],
                                                        "title"
                                                      )
                                                    : this.locale(
                                                        this._checkChange(
                                                          this.state.activeDay
                                                            .value,
                                                          4
                                                        ),
                                                        "title"
                                                      )
                                                }
                                                ingredient={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    4
                                                  )
                                                    ? this.locale(
                                                        this.state.activeDay
                                                          .dinner5[0],
                                                        "description"
                                                      )
                                                    : this.locale(
                                                        this._checkChange(
                                                          this.state.activeDay
                                                            .value,
                                                          4
                                                        ),
                                                        "description"
                                                      )
                                                }
                                                lanchType={
                                                  global.locale
                                                    .home_food_menu_text_7
                                                }
                                                about={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    4
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner5[0].calories +
                                                      " " +
                                                      global.locale.cart_kkal
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        4
                                                      ).calories +
                                                      " " +
                                                      global.locale.cart_kkal +
                                                      ", " +
                                                      (!this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        4
                                                      )
                                                        ? this.state.activeDay
                                                            .dinner5[0].weight
                                                        : this._checkChange(
                                                            this.state.activeDay
                                                              .value,
                                                            4
                                                          ).weight) +
                                                      " " +
                                                      global.locale.cart_gramm
                                                }
                                                ccal={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    4
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner5[0].calories
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        4
                                                      ).calories
                                                }
                                                protein={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    4
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner5[0].protein
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        4
                                                      ).protein
                                                }
                                                fats={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    4
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner5[0].fats
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        4
                                                      ).fats
                                                }
                                                carbo={
                                                  !this._checkChange(
                                                    this.state.activeDay.value,
                                                    4
                                                  )
                                                    ? this.state.activeDay
                                                        .dinner5[0]
                                                        .carbohydrates
                                                    : this._checkChange(
                                                        this.state.activeDay
                                                          .value,
                                                        4
                                                      ).carbohydrates
                                                }
                                                hasChange={
                                                  this.state.activeDay
                                                    .dinner5_may_change == 1
                                                }
                                                changeCallBack={() => {
                                                  this._getAdditional(4);
                                                }}
                                              />
                                            </div>
                                          ) : null}
                                        </div>
                                      </div>
                                    ) : null}
                                    {this.state.activeOrderTab == 1 ? (
                                      <div className="delivery-wrap">
                                        <h3>
                                          {global.locale.cart_delivery_data}
                                          <img src={infoIcon} />
                                        </h3>
                                        <p className="comment">
                                          {global.locale.cart_delivery_info_1}
                                          <br />
                                          {global.locale.cart_delivery_info_2}
                                          <br />
                                          {global.locale.cart_delivery_info_3}
                                          <br />
                                          {global.locale.cart_delivery_info_4}
                                          <br />
                                        </p>
                                        <div className="row align-items-center">
                                          <div className="col-md-4">
                                            <UiSelectDate
                                              excludeDates={
                                                this.state.excludeDaysArr
                                              }
                                              onChange={(data) => {
                                                //console.log(data, formatDate(data) );
                                                this.setState({
                                                  selectedFirstDate:
                                                    formatDate(data),
                                                  selectedStartDeliveryDate:
                                                    formatDate(data),
                                                  dayOffset: 0,
                                                });

                                                storeData(
                                                  "ftdate",
                                                  formatDate(data)
                                                );
                                                this._load();
                                              }}
                                              minDate={
                                                new Date(this.state.minDate)
                                              }
                                              selected={
                                                new Date(
                                                  this.state.selectedFirstDate.replace(
                                                    /-/g,
                                                    "/"
                                                  )
                                                )
                                              }
                                            />
                                          </div>
                                          {/* 
                                          <div className="col-md-4 justify-content-end">
                                            <UiSwitch
                                              callBack={(val) => this.setState({ ftdaypart: val.value })}
                                              disabled
                                            />
                                          </div>
                                          */}
                                          <div className="col-md-4">
                                            <UiSelectScroll
                                              selectedText={
                                                this.state.selectedFTTime
                                              }
                                              selectItems={
                                                this.state.firstTimeArr
                                              }
                                              callBack={(val) =>
                                                this.setState({
                                                  selectedFTTime: val.value,
                                                })
                                              }
                                            />
                                          </div>
                                        </div>
                                        <h3>
                                          {global.locale.cart_delivery_from_day}
                                          <img src={infoIcon} />
                                        </h3>
                                        <p className="comment"></p>
                                        {deliveryItemsList}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <p>{global.locale.cart_delivery_info_5}</p>
                        </div>
                      </div>
                    ) : (
                      <div className="empty">
                        <img className="empty-img" src={planEmptyImg} />
                        <div className="empty-info">
                          <h4>{global.locale.cart_empty_backet}</h4>
                          <UiBtnGreen
                            btnText={global.locale.cart_btn_go_to_menu}
                            link
                            href="/#menu"
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </section>
            <UiFooter />
          </div>
        </div>
      </div>
    );
  }
}
export default CartPage;
